import { APIResponseMany, APIResponseSingle, ContentType } from '@/contentTypes';
import { useAuthStore } from '@/store/auth';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import _ from 'lodash';
import qs from 'qs';

const API_URL = '/locations';
export const API_REF = 'api::location.location';

export async function getLocations(query = {}): AxiosPromise<APIResponseMany<'Location'>> {
    const activeCompany = useAuthStore().getActiveCompany;
    if (!_.has(query, 'filters.department.company.id.$in')) _.set(query, 'filters.department.company.id.$in', activeCompany?.id);
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}?${searchParams}`);
}

export async function getLocation(id: number, query = {}): AxiosPromise<APIResponseSingle<'Location'>> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}/${id}?${searchParams}`);
}

export async function createLocation(data: ContentType<'Location'>): AxiosPromise<APIResponseSingle<'Location'>> {
    return axios.post(`${API_URL}`, {data});
}

export default {
    getLocations,
    getLocation,
    createLocation
};
