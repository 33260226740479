<template>
    <io-autocomplete
        :model-value="itemSelected"
        @update:model-value="onUpdate"
        v-bind="VAutocompleteProps"
        :query="companies"
        :fetch-items="fetchLocations"></io-autocomplete>
</template>

<script setup lang="ts">
    import ioAutocomplete from '@/components/ioAutocomplete.vue';
    import { watch, computed, ref } from 'vue';
    import { getLocations, getLocation } from '@/views/AssetsManagement/composables/locations';
    import { omit } from 'lodash';

    const props = defineProps<{
        modelValue: number | null | any;
        companies: number[];
    }>();

    const emit = defineEmits<{
        (e: 'update:modelValue', value: number | null): void;
    }>();

    const VAutocompleteProps = computed(() => omit(props, ['modelValue']));

    const itemSelected = ref<number | null>();

    const companies = computed(() => props.companies ?? []);

    async function fetchLocations({ page, search, id }: { page?: number; search?: string; id?: number } = {}) {
        if (id) {
            return getLocation(id);
        }

        return getLocations({
            pagination: {
                page,
            },
            sort: ['name:asc'],
            filters: {
                name: {
                    $containsi: search,
                },
                department: {
                    company: {
                        id: {
                            $in: companies.value,
                        },
                    },
                },
                parent: {
                    name: {
                        $null: true,
                    },
                },
            },
        });
    }

    function onUpdate(value: number | null) {
        itemSelected.value = value;
        emit('update:modelValue', value);
    }

    watch(
        () => props.modelValue,
        (value) => {
            itemSelected.value = value;
        },
        {
            immediate: true,
        },
    );
</script>

<style scoped></style>
