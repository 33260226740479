<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <div class="tw-flex tw-gap-x-2">
                    <v-btn
                        elevation="0"
                        density="compact"
                        height="33"
                        width="33"
                        min-width="33"
                        rounded="lg"
                        color="primary"
                        @click="$router.go(-1)">
                        <v-icon class="tw-text-2xl"> mdi-chevron-left </v-icon>
                    </v-btn>
                    <p class="tw-text-2xl tw-font-bold">{{ isAdding ? $t('assets-management.assets.create') : $t('assets-management.assets.edit') }}</p>
                </div>
                <Breadcrumbs
                    class="tw-mt-1 tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <v-row class="tw-py-4">
            <v-col cols="10">
                <v-btn
                    elevation="0"
                    density="compact"
                    height="auto"
                    class="tw-mr-3 tw-h-10"
                    rounded="lg"
                    :class="{ 'tw-bg-primary tw-text-white': showBasicInfo, 'tw-bg-gray-300': !showBasicInfo }"
                    @click="toggleBasicInfo">
                    <p
                        class="tw-text-gray-600"
                        :class="{ 'tw-text-white': showBasicInfo }">
                        {{ $t('assets-management.assets.basicInfo') }}
                    </p>
                </v-btn>
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-mr-3 tw-h-10"
                    rounded="lg"
                    :class="{ 'tw-bg-primary tw-text-white': showAdditionalInfo, 'tw-bg-gray-300': !showAdditionalInfo }"
                    @click="toggleAdditionalInfo"
                    ><p
                        class="tw-text-gray-600"
                        :class="{ 'tw-text-white': showAdditionalInfo }">
                        {{ $t('assets-management.assets.additionalInfo') }}
                    </p></v-btn
                >
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-h-10"
                    rounded="lg"
                    :class="{ 'tw-bg-primary tw-text-white': showOrderInfo, 'tw-bg-gray-300': !showOrderInfo }"
                    @click="toggleOrderInfo">
                    <p
                        class="tw-text-gray-600"
                        :class="{ 'tw-text-white': showOrderInfo }">
                        {{ $t('assets-management.assets.orderInfo') }}
                    </p>
                </v-btn>
            </v-col>
            <v-col class="tw-flex tw-justify-end">
                <v-btn
                    :loading="isLoading"
                    elevation="0"
                    density="compact"
                    class="tw-h-10"
                    rounded="lg"
                    color="primary"
                    @click="onSubmit">
                    <p class="tw-text-white">{{ $t('shared.save') }}</p></v-btn
                >
            </v-col>
        </v-row>
        <v-form
            v-if="(!isAdding && asset.id) || isAdding"
            ref="form">
            <v-row v-if="showBasicInfo">
                <!-- COMPANY -->
                <v-col v-bind="colInputBinds">
                    <autocomplete-companies
                        v-model="asset.company"
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.company')">
                    </autocomplete-companies>
                </v-col>
                <!-- TAG -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.tag')"
                        v-model="asset.tag">
                    </v-text-field>
                </v-col>
                <!-- SERIAL -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.serial')"
                        v-model="asset.serial">
                    </v-text-field>
                </v-col>
                <!-- MODEL -->
                <v-col v-bind="colInputBinds">
                    <autocomplete-models
                        :rules="[rules.required]"
                        :disabled="!asset.company"
                        :companies="asset.company ? [asset.company as number] : []"
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.model')"
                        v-model="asset.model"></autocomplete-models>
                </v-col>
                <!-- STATUS LABEL -->
                <v-col v-bind="colInputBinds">
                    <autocomplete-status-labels
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.statusLabel')"
                        :disabled="!asset.company"
                        :rules="[rules.required]"
                        :companies="asset.company ? [asset.company as number] : []"
                        v-model="asset.status"></autocomplete-status-labels>
                </v-col>
                <!-- LOCATION -->
                <v-col v-bind="colInputBinds">
                    <autocomplete-locations
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.location')"
                        :disabled="!asset.company"
                        :companies="asset.company ? [asset.company as number] : []"
                        v-model="asset.location"></autocomplete-locations>
                </v-col>
                <!-- IMAGE -->
                <v-col v-bind="colInputBinds">
                    <div class="tw-flex tw-flex-col tw-gap-4">
                        <v-file-input
                            v-model="image"
                            variant="solo"
                            flat
                            bg-color="white"
                            rounded="lg"
                            center-affix
                            clearable
                            density="compact"
                            prepend-icon=""
                            :label="$t('assets-management.assets.form.loadImage')"
                            show-size
                            @update:model-value="onImageChanged"
                            accept="image/*">
                        </v-file-input>
                        <v-img
                            v-if="imageSrc"
                            :src="imageSrc"
                            max-width="30rem"
                            contain></v-img>
                    </div>
                </v-col>
                <!-- REQUESTABLE -->
                <v-col v-bind="colInputBinds">
                    <div class="tw-flex tw-gap-x-4">
                        <v-label>{{ $t('assets-management.assets.form.requestable') }}</v-label>
                        <v-switch
                            class=""
                            variant="solo"
                            flat
                            bg-color="white"
                            rounded="lg"
                            center-affix
                            clearable
                            density="compact"
                            v-model="asset.requestable"
                            color="primary"></v-switch>
                    </div>
                </v-col>
                <!-- NOTES -->
                <v-col cols="12">
                    <v-textarea
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.notes')"
                        v-model="asset.notes"></v-textarea>
                </v-col>
            </v-row>
            <v-row v-if="showOrderInfo">
                <!-- ORDER NUMBER -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.orderNumber')"
                        v-model="asset.orderNumber"></v-text-field>
                </v-col>
                <!-- PURCHASE DATE -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.purchaseDate')"
                        v-model="asset.purchaseDate"
                        type="date"></v-text-field>
                </v-col>
                <!-- EOL DATE -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.eolDate')"
                        v-model="asset.eolDate"
                        type="date"></v-text-field>
                </v-col>
                <!-- PURCHASE COST -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.purchaseCost')"
                        v-model="asset.purchaseCost"
                        type="number"
                        suffix="€ (EUR)"></v-text-field>
                </v-col>
                <!-- SUPPLIER -->
                <v-col v-bind="colInputBinds">
                    <autocomplete-suppliers
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.supplier')"
                        :disabled="!asset.company"
                        :companies="asset.company ? [asset.company as number] : []"
                        v-model="asset.supplier"></autocomplete-suppliers>
                </v-col>
            </v-row>
            <v-row v-if="showAdditionalInfo">
                <!-- NAME -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.name')"
                        v-model="asset.name"></v-text-field>
                </v-col>
                <!-- WARRANTY -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('assets-management.assets.form.warranty')"
                        suffix="meses"
                        v-model="asset.warrantyMonths"
                        type="number"></v-text-field>
                </v-col>
                <!-- BYOD -->
                <v-col v-bind="colInputBinds">
                    <div class="tw-flex tw-items-start tw-gap-x-4">
                        <v-label class="tw-pt-2">{{ $t('assets-management.assets.form.byod') }}</v-label>
                        <v-switch
                            class=""
                            variant="solo"
                            flat
                            bg-color="white"
                            :hint="$t('assets-management.assets.form.byodHint')"
                            persistent-hint
                            rounded="lg"
                            center-affix
                            clearable
                            density="compact"
                            v-model="asset.byod"
                            color="primary"></v-switch>
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </div>
    <v-overlay
        :model-value="isLoading"
        class="tw-items-center tw-justify-center"
        persistent>
        <v-progress-circular
            indeterminate
            size="64"
            color="primary"></v-progress-circular>
    </v-overlay>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import AutocompleteCompanies from '@/views/AssetsManagement/Companies/AutocompleteCompanies.vue';
    import AutocompleteModels from '../Models/AutocompleteModels.vue';
    import AutocompleteLocations from '../Locations/AutocompleteLocations.vue';
    import AutocompleteSuppliers from '../Suppliers/AutocompleteSuppliers.vue';
    import AutocompleteStatusLabels from '../StatusLabels/AutocompleteStatusLabels.vue';
    import { ContentType } from '@/contentTypes';
    import useRules from '@/composables/rules';
    import { createAsset, API_REF, getAsset, updateAsset } from '@/views/AssetsManagement/composables/assets';
    import { uploadFiles } from '@/composables/upload';
    import { getErrorKey } from '@/composables/error';
    import ioImg from '@/components/ioImg.vue';
    import { useAuthStore } from '@/store/auth';
    import { useRoute, useRouter } from 'vue-router';
    import { useMedia, parseMediaToFile } from '@/composables/useMedia';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';

    const useAuth = useAuthStore();
    const user = useAuth.user;

    const isLoading = ref(false);
    const rules = useRules();
    const form = ref();
    const $route = useRoute();
    const $router = useRouter();
    const hasChangedImage = ref(false);
    const $alert = useAlert();
    const $t = useI18n().t;

    const showBasicInfo = ref(true);
    const showAdditionalInfo = ref(false);
    const showOrderInfo = ref(false);

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('assets-management.title'),
                to: { name: 'AM-Assets' },
            },
            {
                title: $t('assets-management.discovery.title'),
                to: { name: 'AM-Discoveries' },
            },
            {
                title: isAdding.value ? $t('assets-management.assets.create') : $t('assets-management.assets.edit'),
            },
        ];
    });

    const asset = ref<ContentType<'Asset'>>({
        company: null,
        tag: '',
        serial: '',
        notes: '',
        model: $route.params?.model || null,
        location: null,
        requestable: false,
        name: $route.params?.name || '',
        deviceIdHA: $route.params?.entityId || '',
        warrantyMonths: null,
        byod: false,
        orderNumber: '',
        purchaseDate: null,
        eolDate: null,
        supplier: null,
        purchaseCost: null,
    });

    const image = ref<File[]>();

    const imageSrc = computed(() => {
        if (image.value && image.value.length > 0) {
            return URL.createObjectURL(image.value[0]);
        }

        return '';
    });

    const isAdding = computed(() => {
        return !$route.params?.id;
    });

    const isCloning = computed(() => {
        return $route?.path.includes('clone');
    });

    const colInputBinds = {
        cols: 12,
        lg: 4,
        md: 6,
    };

    async function onSubmit() {
        if (!form.value) return;

        $alert.hideAlert();
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;
        try {
            if (isCloning.value) {
                asset.value.id = undefined;
            }
            const func = isAdding.value || isCloning.value ? createAsset(asset.value) : updateAsset(asset.value.id as number, asset.value);
            const { data } = await func;
            try {
                if (image.value && hasChangedImage.value) {
                    await uploadFiles({
                        files: image.value,
                        refId: data.data.id,
                        ref: API_REF,
                        field: 'image',
                    });
                }
            } catch (error) {
                $alert.showAlert({
                    text: $t(getErrorKey(error, 'assets-management.assets.errors')),
                    title: $t('assets-management.assets.errors.uploading-image'),
                    type: 'error',
                    timeout: 0,
                });
            }

            $alert.showAlert({
                title: isAdding.value ? $t('assets-management.assets.success.create') : $t('assets-management.assets.success.edit'),
                type: 'success',
                timeout: 5000,
            });
            $router.push({ name: 'AM-Assets' });
        } catch (error) {
            $alert.showAlert({
                text: $t(getErrorKey(error, 'assets-management.assets.errors')),
                title: $t('assets-management.assets.errors.creating-asset'),
                type: 'error',
                timeout: 0,
            });
        } finally {
            isLoading.value = false;
        }
    }

    function toggleBasicInfo() {
        if (!showBasicInfo.value) showBasicInfo.value = !showBasicInfo.value;
        if (showBasicInfo.value) {
            showOrderInfo.value = false;
            showAdditionalInfo.value = false;
        }
    }

    function toggleOrderInfo() {
        if (!showOrderInfo.value) showOrderInfo.value = !showOrderInfo.value;
        if (showOrderInfo.value) {
            showBasicInfo.value = false;
            showAdditionalInfo.value = false;
        }
    }

    function toggleAdditionalInfo() {
        if (!showAdditionalInfo.value) showAdditionalInfo.value = !showAdditionalInfo.value;
        if (showAdditionalInfo.value) {
            showBasicInfo.value = false;
            showOrderInfo.value = false;
        }
    }

    function onImageChanged() {
        hasChangedImage.value = true;
    }

    async function init() {
        if (isAdding.value) return;

        isLoading.value = true;
        try {
            const { data } = await getAsset(parseInt($route.params.id as string), {
                populate: {
                    model: {
                        fields: ['id'],
                    },
                    status: {
                        fields: ['id'],
                    },
                    location: {
                        fields: ['id'],
                    },
                    supplier: {
                        fields: ['id'],
                    },
                    company: {
                        fields: ['id'],
                    },
                    image: true,
                },
            });
            asset.value = {
                ...data.data,
                model: (data.data.model as ContentType<'Model'>)?.id,
                status: (data.data.status as ContentType<'StatusLabel'>)?.id,
                location: (data.data.location as ContentType<'Location'>)?.id,
                supplier: (data.data.supplier as ContentType<'Supplier'>)?.id,
                company: (data.data.company as ContentType<'Company'>)?.id,
            };
            if (data.data.image) {
                const img = await parseMediaToFile(data.data.image as ContentType<'Media'>);
                image.value = [img];
            }
        } catch (error) {
            $alert.showAlert({
                text: $t(getErrorKey(error, 'assets-management.assets.errors')),
                title: $t('assets-management.assets.errors.loading-asset'),
                type: 'error',
                timeout: 0,
                closable: true,
            });
        } finally {
            isLoading.value = false;
        }
    }

    init();
</script>

<style>
    .e-panels .v-expansion-panel-text__wrapper {
        padding-inline: 0 !important;
    }
</style>
