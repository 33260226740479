<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('settings.alarmist.title') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>

    <div class="content-container">
        <div
            v-if="errorMessage"
            class="tw-mb-4">
            <v-alert
                type="error"
                variant="tonal"
                density="compact"
                >{{ errorMessage }}</v-alert
            >
        </div>
        <v-row class="tw-justify-between tw-py-4">
            <v-col
                class="tw-flex tw-gap-x-4"
                cols="12"
                lg="7">
                <v-text-field
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('shared.search')"
                    v-model="search">
                    <template #append-inner>
                        <v-icon class="tw-text-gray">mdi-magnify</v-icon>
                    </template>
                </v-text-field>
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-h-10 lg:tw-w-auto"
                    rounded="lg"
                    color="gray-300"
                    :to="{ name: 'AM-CreateAsset' }"
                    ><v-icon class="tw-pr-2 tw-text-xl tw-text-black">mdi-filter</v-icon>
                    {{ $t('shared.filters') }}
                </v-btn>
            </v-col>
            <v-col
                class="tw-order-first tw-mb-2 lg:tw-order-last lg:tw-mb-0 lg:tw-flex lg:tw-justify-end"
                cols="12"
                md="auto">
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-mb-2 tw-mr-2 tw-h-10 xs:tw-mb-0"
                    rounded="lg"
                    color="primary"
                    :to="{ name: 'CreateAlarmist' }"
                    ><v-icon class="tw-pr-2 tw-text-xl tw-text-white">mdi-plus</v-icon>
                    <p class="tw-text-white">
                        {{ $t('settings.alarmist.create') }}
                    </p>
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table-server
            hover
            density="comfortable"
            :loading="isLoading"
            :loading-text="$t('shared.loading')"
            :headers="headers"
            :items-per-page-options="[10, 25, 50, 100]"
            v-model:items-per-page="pageSize"
            :no-data-text="$t('settings.errors.not_found')"
            v-model:page="page"
            :items-length="total"
            show-select
            @update:options="onOptionsUpdated"
            v-model="itemsSelected"
            :items="tableItems"
            height="calc(100vh - 280px)"
            :fixed-header="true">
            <template #item.trigger[0].entity_id="value">
                <p>{{ Array.isArray(value.item.trigger[0].entity_id) ? value.item.trigger[0].entity_id[0] : value.item.trigger[0].entity_id }}</p>
            </template>
            <template #item.action[0].event_data.criticality="value">
                <div class="tw-w-16">
                    <v-chip
                        size="small"
                        class="tw-flex tw-w-full tw-justify-center"
                        :class="{
                            'tw-text-red': value.item.action[0].event_data.criticality == 'high',
                            'tw-text-yellow': value.item.action[0].event_data.criticality == 'medium',
                            'tw-text-green': value.item.action[0].event_data.criticality == 'low',
                        }">
                        {{ $t(`settings.alarmist.fields.${value.item.action[0].event_data.criticality}`) }}
                    </v-chip>
                </div>
            </template>
            <template #item.trigger[0].for="value">
                <p>
                    {{
                        `${value.item.trigger[0].for.hours} ${$t('settings.alarmist.fields.hours')} ${value.item.trigger[0].for.minutes} ${$t('settings.alarmist.fields.minutes')} ${value.item.trigger[0].for.seconds} ${$t('settings.alarmist.fields.seconds')}`
                    }}
                </p>
            </template>
            <template #item.actions="{ item }">
                <div class="tw-flex tw-gap-1">
                    <v-btn
                        color="yellow-300"
                        icon
                        size="x-small"
                        class="tw-rounded-md"
                        :to="{ name: 'EditAlarmist', params: { id: item.id } }">
                        <v-icon class="tw-text-base tw-text-xl tw-text-white">mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                        color="red-400"
                        size="x-small"
                        class="tw-rounded-md"
                        icon
                        @click="executeAction(item.id)">
                        <v-icon class="tw-text-base tw-text-xl tw-text-white">mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table-server>
    </div>
    <ConfirmDelete
        v-model="isDeleting"
        @action="onDelete" />
</template>

<script setup lang="ts">
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import { ContentType } from '@/contentTypes';
    import { computed, ref, watch } from 'vue';
    import { useAuthStore } from '@/store/auth';
    import { getAssets, deleteAssets, deleteAsset } from '@/views/AssetsManagement/composables/assets';
    import { getAlarmist, deleteAlarmist } from '@/views/Alarmist/composables/alarmist';
    import { VDataTableOptions } from '@/types';
    import { getErrorKey } from '@/composables/error';
    import { useI18n } from 'vue-i18n';
    import ioImg from '@/components/ioImg.vue';
    import { isArray, sortBy } from 'lodash';
    import ConfirmDelete from '@/components/ConfirmDelete.vue';

    const useAuth = useAuthStore();
    const user = useAuth.user;

    const $t = useI18n().t;

    const search = ref('');

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('settings.title'),
            },
            {
                title: $t('settings.alarmist.title'),
                to: { name: 'AlarmistSettings' },
            },
        ];
    });

    const assets = ref<ContentType<'Asset'>[]>([]);
    const alarmists = ref([]);
    const tableItems = ref([]);
    const itemsSelected = ref<number[]>([]);
    const itemToDelete = ref<number | null>(null);
    const isLoading = ref(true);
    const pageCount = ref(0);
    const pageSize = ref(10);
    const errorMessage = ref('');
    const total = ref(0);
    const page = ref(1);
    const sort = ref<VDataTableOptions['sortBy']>([
        {
            key: 'id',
            order: 'desc',
        },
    ]);
    const firstUpdate = ref(true);
    const isDeleting = ref(false);

    const actionOptions = [
        {
            text: $t('shared.delete'),
            value: 'delete',
        },
    ];
    const actionSelected = ref('');

    const headers = [
        {
            title: $t('settings.alarmist.fields.name'),
            sortable: true,
            key: 'assetName',
        },
        {
            title: $t('settings.alarmist.fields.metric'),
            sortable: true,
            key: 'name',
        },
        {
            title: $t('settings.alarmist.fields.above'),
            sortable: true,
            key: 'trigger[0].above',
        },

        {
            title: $t('settings.alarmist.fields.below'),
            sortable: true,
            key: 'trigger[0].below',
        },
        {
            title: $t('settings.alarmist.fields.for'),
            sortable: true,
            key: 'trigger[0].for',
        },
        {
            title: $t('settings.alarmist.fields.location'),
            sortable: true,
            key: 'location',
        },
        {
            title: $t('settings.alarmist.fields.criticality'),
            sortable: true,
            key: 'action[0].event_data.criticality',
        },
        {
            sortable: false,
            key: 'actions',
        },
    ] as VDataTableServer['headers'];

    const init = async () => {
        try {
            await getAssets({
                filters: {
                    $or: [
                        {
                            name: {
                                $containsi: search.value,
                            },
                        },
                    ],
                    $and: [
                        {
                            name: {
                                $ne: 'Portátil',
                            },
                        },
                        {
                            name: {
                                $ne: 'TV LG',
                            },
                        },
                        {
                            name: {
                                $ne: 'Server Dell 730',
                            },
                        },
                        {
                            name: {
                                $ne: 'Frigorifico',
                            },
                        },
                    ],
                },
                populate: ['supplier', 'image', 'location'],
            }).then((response) => {
                assets.value = response.data.data;
                fetchAlarmist();
            });
        } catch (error) {
            console.log(error);
        }
    };

    const fetchAlarmist = async () => {
        try {
            await getAlarmist({
                history: false,
            })
                .then((response) => {
                    alarmists.value = response.data;
                    //find asset for each alarmist, so we can show the location
                    console.log(alarmists.value);
                    for (let alarmist of alarmists.value) {
                        let entityId = alarmist.trigger[0].entity_id;
                        if (isArray(entityId)) entityId = entityId[0];

                        let asset = assets.value.find((asset) => asset?.entityIdsHA?.includes(entityId));
                        if (asset) {
                            alarmist['assetName'] = asset?.name;
                            alarmist['location'] = asset?.location?.name;
                        }
                    }
                    total.value = response.data.length;
                    pageCount.value = Math.ceil(total.value / pageSize.value);

                    tableItems.value = alarmists.value.slice((page.value - 1) * pageSize.value, page.value * pageSize.value);
                })
                .finally(() => {
                    isLoading.value = false;
                });
        } catch (error) {
            console.log(error);
        }
    };

    init();

    function onOptionsUpdated(options: VDataTableOptions) {
        if (firstUpdate.value) {
            firstUpdate.value = false;
            return;
        }
        page.value = options.page;
        pageSize.value = options.itemsPerPage;
        pageCount.value = Math.ceil(total.value / pageSize.value);

        if (options.sortBy.length > 0) {
            //sort by asc or desc
            if (options.sortBy[0].order == 'asc') {
                alarmists.value = sortBy(alarmists.value, options.sortBy[0].key);
            } else {
                alarmists.value = sortBy(alarmists.value, options.sortBy[0].key).reverse();
            }
        }

        tableItems.value = alarmists.value.slice((page.value - 1) * pageSize.value, page.value * pageSize.value);
    }

    function executeAction(id: any) {
        if (id) (isDeleting.value = true), (itemToDelete.value = id);
        if (!id) {
            if (actionSelected.value === 'delete') isDeleting.value = true;
        }
    }

    async function onDelete(action: string) {
        if (action !== 'yes') return;
        isLoading.value = true;
        try {
            if (itemToDelete.value) await deleteAlarmist(itemToDelete.value);
            if (itemsSelected.value.length > 0) await deleteAlarmist(itemsSelected.value);
            init();
        } catch (error) {
            const messageKey = getErrorKey(error, `settings.errors`);
            errorMessage.value = $t(messageKey);
        } finally {
            itemToDelete.value = null;
            isLoading.value = false;
        }
    }

    watch(search, () => {
        init();
    });

    init();
</script>

<style scoped></style>
