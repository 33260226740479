<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('intelligence.analytics.title') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <v-row class="tw-py-4">
            <v-col
                cols="12"
                md="6"
                lg="">
                <v-select
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('intelligence.analytics.energy.labels.selectModule')"
                    :items="modules"
                    v-model="selectedModule">
                </v-select>
            </v-col>
            <v-col
                cols="12"
                md="6"
                lg="">
                <v-select
                    :disabled="selectedModule === null"
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('intelligence.analytics.energy.labels.selectAsset')"
                    :items="assets"
                    item-title="name"
                    item-value="id"
                    v-model="selectedAsset">
                </v-select>
            </v-col>
            <v-col
                cols="12"
                md="6"
                lg="">
                <v-select
                    :disabled="selectedAsset === null"
                    multiple
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('intelligence.analytics.energy.labels.selectSensors')"
                    :items="sensors"
                    item-value="value"
                    v-model="selectedSensors">
                </v-select>
            </v-col>
            <v-col
                cols="12"
                md="6"
                lg="">
                <v-date-input
                    :disabled="isDatePickerDisabled"
                    :allowed-dates="allowedDates"
                    v-model="selectedDates"
                    multiple="range"
                    prepend-icon=""
                    append-inner-icon="mdi-calendar"
                    color="primary"
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('intelligence.analytics.energy.labels.selectDate')">
                </v-date-input>
                <!-- <v-date-picker
                    width="100%"
                    :disabled="isDatePickerDisabled"
                    :allowed-dates="allowedDates"
                    v-model="selectedDates"
                    multiple="range"
                    color="primary"></v-date-picker> -->
            </v-col>
            <!-- <v-col
                class="tw-flex tw-justify-center md:tw-justify-end"
                lg="2">
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-h-10 lg:tw-w-full"
                    rounded="lg"
                    color="primary"
                    ><v-icon class="tw-pr-2 tw-text-xl tw-text-white">mdi-filter</v-icon>
                    <p class="tw-text-white">
                        {{ $t('shared.filter') }}
                    </p>
                </v-btn>
            </v-col> -->
        </v-row>
        <v-row>
            <v-col
                cols="6"
                v-if="selectedSensors.length > 0"
                class="tw-mb-4 tw-h-96"
                v-for="sensor in assetHistory.filter((item: any) => selectedSensors.includes(item[0].attributes.friendly_name))"
                :key="sensor">
                <v-card
                    variant="flat"
                    class="tw-h-96">
                    <Chart
                        class="tw-mt-1"
                        type="line"
                        :data="getData(sensor)"
                        :xAxisInfo="getXAxisInfo(sensor)"
                        :title="sensor[0].attributes.friendly_name"
                        :series_name="sensor[0].attributes.unit_of_measurement" />
                </v-card>
            </v-col>
        </v-row>
        <v-overlay
            :model-value="isLoading"
            class="tw-items-center tw-justify-center"
            persistent>
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { getAsset, getAssets } from '@/views/AssetsManagement/composables/assets';
    import moment from 'moment';
    import Chart from '@/components/charts/Chart.vue';
    import { ContentType } from '@/contentTypes';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';
    import { onMounted, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { VDateInput } from 'vuetify/labs/VDateInput';

    const $t = useI18n().t;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('intelligence.title'),
            },
            {
                title: $t('intelligence.analytics.title'),
                to: { name: 'Analytics' },
            },
        ];
    });

    const useAuth = useAuthStore();
    const user = useAuth.user;

    const isLoading = ref(false);
    const modules = ['Energia', 'Ambiente'];
    const assets = ref<ContentType<'Asset'>[]>([]);
    const asset = ref<ContentType<'Asset'> | null>(null);
    const sensors = ref<ContentType<'Asset'>[]>([]);

    const selectedModule = ref<string | null>(null);
    const selectedAsset = ref<string | null>(null);
    const selectedSensors = ref<string[]>([]);

    const selectedDates = ref<string[]>([]);
    const isDatePickerDisabled = ref(true);

    const allowedDates = ref<string[]>([]);
    let done = ref(false);

    //watch selectedModule
    watch(selectedModule, (newValue) => {
        if (newValue) {
            selectedAsset.value = null;
            selectedSensors.value = [];
            fetchAssets();
        }
    });

    //watch selectedAsset and fetch asset
    watch(selectedAsset, (newValue) => {
        if (newValue) {
            selectedSensors.value = [];
            fetchAsset(newValue);
        }
    });

    //handle allowed dates
    watch(selectedSensors, (newValue) => {
        if (selectedSensors.value.length > 0) {
            isDatePickerDisabled.value = false;
            // let filteredSelectedSensors = [];
            // filteredSelectedSensors = asset.value.history.filter((item: any) => selectedSensors.value.includes(item[0].attributes.friendly_name));
            // filteredSelectedSensors = filteredSelectedSensors.map((item: any) => {
            //     item.forEach((i: any) => {
            //         if (!allowedDates.value.includes(moment(i.last_changed).format('YYYY-MM-DD'))) allowedDates.value.push(moment(i.last_changed).format('YYYY-MM-DD'));
            //     });
            // });
        } else {
            selectedDates.value = [];
            isDatePickerDisabled.value = true;
        }
    });

    //watch selected dates, so we can filter the data by date
    watch(selectedDates, (newValue) => {
        if (newValue.length > 1) {
            fetchAsset(selectedAsset.value);
        }
    });

    const assetHistory = computed(() => {
        return asset.value?.history;
    });

    //fetch assets (for assets select)
    async function fetchAssets() {
        isLoading.value = true;
        try {
            const { data } = await getAssets({
                filters: {
                    $and: [
                        {
                            name: {
                                $ne: 'Portátil',
                            },
                        },
                        {
                            name: {
                                $ne: 'TV LG',
                            },
                        },
                        {
                            name: {
                                $ne: 'Server Dell 730',
                            },
                        },
                        {
                            name: {
                                $ne: 'Frigorifico',
                            },
                        },
                    ],
                },
                stateChanges: true,
                minimalResponse: true,
                significantChangesOnly: true,
                populate: ['supplier', 'image', 'location'],
            });
            assets.value = data.data;
            done.value = true;
        } catch (error) {
            console.error('Error fetching asset data:', error);
        } finally {
            isLoading.value = false;
        }
    }

    //fetch asset (for sensors select)
    async function fetchAsset(id: number) {
        isLoading.value = true;
        try {
            const { data } = await getAsset(id, {
                startTime: selectedDates.value[0] ? moment(selectedDates.value[0]).format('YYYY-MM-DD') : '',
                endTime: selectedDates.value[0] ? moment(selectedDates.value[selectedDates.value.length - 1]).format('YYYY-MM-DD') : '',
                stateChanges: true,
                minimalResponse: true,
                significantChangesOnly: true,
                populate: ['supplier', 'image', 'location'],
            });
            asset.value = data.data;
            if (selectedDates.value.length > 0) {
                const startDate = moment(selectedDates.value[0]).startOf('day');
                const endDate = moment(selectedDates.value[selectedDates.value.length - 1]).endOf('day');
                asset.value.history = asset.value.history.map((item) => {
                    const friendlyName = item[0].attributes.friendly_name;
                    const filteredByDates = item.filter((i) => {
                        // allowedDates.value.push(moment(i.last_changed).format('YYYY-MM-DD'));
                        return moment(i.last_changed).isBetween(startDate, endDate, null, '[]');
                    });
                    if (filteredByDates.length === 0) {
                        filteredByDates.push({ attributes: { friendly_name: friendlyName } });
                    }
                    if (!filteredByDates[0]?.attributes?.friendly_name) {
                        filteredByDates[0] = { attributes: { friendly_name: friendlyName } };
                    }
                    return filteredByDates;
                });
            }
            // allowedDates.value = [...new Set(allowedDates.value)];
            sensors.value = asset?.value?.history?.map((item: any) => item[0].attributes.friendly_name);
            done.value = true;
        } catch (error) {
            console.error('Error fetching asset data:', error);
        } finally {
            getData(asset?.value?.history);
            isLoading.value = false;
        }
    }

    //get data for chart
    function getData(data: any) {
        return data?.map((item: AssetHistory) => item.state);
    }

    //get x axis info for chart
    function getXAxisInfo(data: any) {
        if (data.length === 1) {
            return [moment(selectedDates.value[0]).format('YYYY-MM-DD HH:mm:ss'), moment(selectedDates.value[selectedDates.value.length - 1]).format('YYYY-MM-DD HH:mm:ss')];
        } else {
            return data.map((item: AssetHistory) => moment(item.last_changed).format('YYYY-MM-DD HH:mm:ss'));
        }
    }

    //fetch assets on mounted
    onMounted(() => {
        fetchAssets();
    });
</script>

<style scoped></style>
<style>
    .v-date-picker-header {
        display: none;
    }
</style>
