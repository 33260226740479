<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('dashboards.dashboard') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <v-row class="tw-justify-between tw-py-4">
            <v-col>
                <Blueprint></Blueprint>
            </v-col>
        </v-row>
    </div>
</template>
<script setup lang="ts">
    import Blueprint from '@/components/Blueprint.vue';
    import { useAuthStore } from '@/store/auth';
    import { useI18n } from 'vue-i18n';
    import { ref, provide, computed, watch } from 'vue';
    import IoImg from '@/components/ioImg.vue';

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('dashboards.dashboard'),
            },
            {
                title: $t('dashboards.blueprint.title'),
                to: { name: 'BlueprintDashboard' },
            },
        ];
    });

    const $t = useI18n().t;

    const useAuth = useAuthStore();
    const token = useAuth.token;
    const user = useAuth.user;
</script>
