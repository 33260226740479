<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('dashboards.dashboard') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <v-row>
            <v-col
                cols="12"
                lg="4"
                md="4"
                sm="6">
                <v-card
                    variant="flat"
                    class="mx-auto">
                    <template v-slot:prepend>
                        <v-icon class="tw-rounded-md tw-bg-red-100 tw-bg-opacity-100 tw-p-5 tw-text-red-500"> mdi-thermometer </v-icon>
                    </template>
                    <template v-slot:title>
                        <div class="tw-flex tw-justify-end">
                            <p class="tw-text-xl tw-font-semibold">12.9</p>
                        </div>
                    </template>
                    <template v-slot:text>
                        <div class="tw-flex tw-justify-between">
                            <p class="tw-text-gray-600">Temperatura</p>
                            <p class="tw-font-semibold">ºC</p>
                        </div>
                        <div class="tw-flex tw-justify-end"></div>
                    </template>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                lg="4"
                md="4"
                sm="6">
                <v-card
                    variant="flat"
                    class="mx-auto">
                    <template v-slot:prepend>
                        <v-icon class="tw-rounded-md tw-bg-blue-100 tw-bg-opacity-100 tw-p-5 tw-text-blue-500"> mdi-water-percent </v-icon>
                    </template>
                    <template v-slot:title>
                        <div class="tw-flex tw-justify-end">
                            <p class="tw-text-xl tw-font-semibold">33</p>
                        </div>
                    </template>
                    <template v-slot:text>
                        <div class="tw-flex tw-justify-between">
                            <p class="tw-text-gray-600">Humidade</p>
                            <p class="tw-font-semibold">%</p>
                        </div>
                        <div class="tw-flex tw-justify-end"></div>
                    </template>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                lg="4"
                md="4"
                sm="6">
                <v-card
                    variant="flat"
                    class="mx-auto">
                    <template v-slot:prepend>
                        <v-icon class="tw-rounded-md tw-bg-red-100 tw-bg-opacity-100 tw-p-5 tw-text-red-500"> mdi-weather-pouring </v-icon>
                    </template>
                    <template v-slot:title>
                        <div class="tw-flex tw-justify-end">
                            <p class="tw-text-xl tw-font-semibold">00</p>
                        </div>
                    </template>
                    <template v-slot:text>
                        <div class="tw-flex tw-justify-between">
                            <p class="tw-text-gray-600">Precipitação</p>
                            <p class="tw-font-semibold">mm</p>
                        </div>
                        <div class="tw-flex tw-justify-end"></div>
                    </template>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                lg="6"
                md="4"
                sm="6">
                <v-card
                    variant="flat"
                    class="mx-auto">
                    <template v-slot:prepend>
                        <v-icon class="tw-rounded-md tw-bg-orange-100 tw-bg-opacity-100 tw-p-5 tw-text-orange-500"> mdi-windsock </v-icon>
                    </template>
                    <template v-slot:title>
                        <div class="tw-flex tw-justify-end">
                            <p class="tw-font-semibold">SE</p>
                        </div>
                    </template>
                    <template v-slot:text>
                        <div class="tw-flex tw-justify-between">
                            <p class="tw-text-gray-600">Direção do Vento</p>
                        </div>
                        <div class="tw-flex tw-justify-end"></div>
                    </template>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                lg="6"
                md="4"
                sm="6">
                <v-card
                    variant="flat"
                    class="mx-auto">
                    <template v-slot:prepend>
                        <v-icon class="tw-rounded-md tw-bg-gray-100 tw-bg-opacity-100 tw-p-5 tw-text-gray-500"> mdi-weather-windy </v-icon>
                    </template>
                    <template v-slot:title>
                        <div class="tw-flex tw-justify-end">
                            <p class="tw-text-xl tw-font-semibold">1.6</p>
                        </div>
                    </template>
                    <template v-slot:text>
                        <div class="tw-flex tw-justify-between">
                            <p class="tw-text-gray-600">Velocidade do Vento</p>
                            <p class="tw-font-semibold">km/h</p>
                        </div>
                        <div class="tw-flex tw-justify-end"></div>
                    </template>
                </v-card>
            </v-col>
        </v-row>
    </div>
    <div
        class="content-container"
        v-if="done">
        <v-row>
            <v-col cols="6">
                <!-- Weather Card -->
                <v-card
                    variant="flat"
                    class="mx-auto tw-mb-6 tw-w-full tw-pr-2 tw-pt-2">
                    <v-card-title>
                        <p class="tw-text-base tw-font-semibold">Home</p>
                    </v-card-title>
                    <v-card-subtitle>
                        {{ weather.description.split('|')[1].trim() }}
                    </v-card-subtitle>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col
                                class="tw-flex tw-items-center"
                                cols="6">
                                <p class="text-h3 text-center">{{ Math.floor(weather.temperature) }}&deg;C</p>
                                <v-img
                                    contain
                                    height="120"
                                    width="120"
                                    :src="`https://www.ipma.pt/bin/icons/svg/weather/w_ic_${weather.icon[2]}_${weather.icon.substring(0, 2)}anim.svg`">
                                </v-img>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-row>
                        <v-col
                            cols="6"
                            class="tw-flex">
                            <v-list-item
                                density="compact"
                                prepend-icon="mdi-weather-windy">
                                <v-list-item-subtitle>{{ weather.wind_speed }} m/s</v-list-item-subtitle>
                            </v-list-item>

                            <v-list-item
                                density="compact"
                                prepend-icon="mdi-weather-pouring">
                                <v-list-item-subtitle>{{ weather.precipitation_probability * 100 }}%</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <v-row class="tw-text-center">
                        <v-col>
                            <v-list-item density="compact">
                                <p class="tw-text-sm">Radiação solar</p>
                                <v-list-item-subtitle class="tw-text-center">{{ weather.ultraviolet }} W/m2</v-list-item-subtitle>
                            </v-list-item></v-col
                        >
                        <v-col>
                            <v-list-item density="compact">
                                <p class="tw-text-sm">Humidade</p>
                                <v-list-item-subtitle class="tw-text-center"> {{ weather.humidity }} %</v-list-item-subtitle>
                            </v-list-item></v-col
                        >
                        <v-col>
                            <v-list-item density="compact">
                                <p class="tw-text-sm">Pressão</p>

                                <v-list-item-subtitle class="tw-text-center">{{ weather.pressure }} hPa</v-list-item-subtitle>
                            </v-list-item></v-col
                        >
                    </v-row>

                    <v-row class="tw-text-center">
                        <v-col>
                            <v-list-item density="compact">
                                <p class="tw-text-sm">Sensação Térmica</p>
                                <v-list-item-subtitle class="tw-text-center">{{ weather.feels_like }} &deg;C</v-list-item-subtitle>
                            </v-list-item></v-col
                        >
                        <v-col>
                            <v-list-item density="compact">
                                <p class="tw-text-sm">Rajada de vento</p>
                                <v-list-item-subtitle class="tw-text-center"> {{ weather.wind_gust }} m/s</v-list-item-subtitle>
                            </v-list-item></v-col
                        >
                        <v-col>
                            <v-list-item density="compact">
                                <p class="tw-text-sm">Volume de Chuva</p>
                                <v-list-item-subtitle class="tw-text-center">{{ weather.rain_volume ? weather.rain_volume : '-' }} mml</v-list-item-subtitle>
                            </v-list-item></v-col
                        >
                    </v-row>

                    <v-row class="tw-text-center">
                        <v-col>
                            <v-list-item density="compact">
                                <p class="tw-text-sm">Nebulosidade</p>
                                <v-list-item-subtitle class="tw-text-center">{{ weather.clouds }} %</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        <v-col>
                            <v-list-item density="compact">
                                <p class="tw-text-sm">Visibilidade</p>
                                <v-list-item-subtitle class="tw-text-center"> {{ weather.visibility }} m</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        <v-col>
                            <v-list-item density="compact">
                                <p class="tw-text-sm">Direção do vento</p>
                                <v-list-item-subtitle class="tw-text-center">{{ weather.wind_direction }} &deg;</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                    </v-row>

                    <v-row class="tw-text-center">
                        <v-col>
                            <v-list-item density="compact">
                                <p class="tw-text-sm">Volume de neve</p>
                                <v-list-item-subtitle class="tw-text-center">{{ weather.snow_volume ? weather.snow_volume : '-' }} mml</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col>
                <v-row>
                    <v-col cols="12">
                        <v-card
                            variant="flat"
                            class="mx-auto">
                            <template v-slot:prepend>
                                <v-icon class="tw-rounded-md tw-bg-gray-100 tw-bg-opacity-100 tw-p-5 tw-text-gray-500"> mdi-weather-windy-variant </v-icon>
                            </template>
                            <template v-slot:title>
                                <div class="tw-flex tw-justify-end">
                                    <p class="tw-text-xl tw-font-semibold">53</p>
                                </div>
                            </template>
                            <template v-slot:text>
                                <div class="tw-flex tw-justify-between">
                                    <p class="tw-text-gray-600">PM2.5 - Pressão atmosférica</p>
                                    <p class="tw-font-semibold">Kpa</p>
                                </div>
                                <div class="tw-flex tw-justify-end"></div>
                            </template>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card
                            variant="flat"
                            class="mx-auto">
                            <template v-slot:prepend>
                                <v-icon class="tw-rounded-md tw-bg-yellow-100 tw-bg-opacity-100 tw-p-5 tw-text-yellow-500"> mdi-volume-high </v-icon>
                            </template>
                            <template v-slot:title>
                                <div class="tw-flex tw-justify-end">
                                    <p class="tw-text-xl tw-font-semibold">32</p>
                                </div>
                            </template>
                            <template v-slot:text>
                                <div class="tw-flex tw-justify-between">
                                    <p class="tw-text-gray-600">Ruído</p>
                                    <p class="tw-font-semibold">dB</p>
                                </div>
                                <div class="tw-flex tw-justify-end"></div>
                            </template>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card
                            variant="flat"
                            class="mx-auto">
                            <template v-slot:prepend>
                                <v-icon class="tw-rounded-md tw-bg-red-100 tw-bg-opacity-100 tw-p-5 tw-text-red-500"> mdi-molecule-co2 </v-icon>
                            </template>
                            <template v-slot:title>
                                <div class="tw-flex tw-justify-end">
                                    <p class="tw-text-xl tw-font-semibold">1235</p>
                                </div>
                            </template>
                            <template v-slot:text>
                                <div class="tw-flex tw-justify-between">
                                    <p class="tw-text-gray-600">CO2</p>
                                    <p class="tw-font-semibold">ppm</p>
                                </div>
                                <div class="tw-flex tw-justify-end"></div>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
    <v-overlay
        :model-value="isLoading"
        class="tw-items-center tw-justify-center"
        persistent>
        <v-progress-circular
            indeterminate
            size="64"
            color="primary"></v-progress-circular>
    </v-overlay>
</template>

<script setup lang="ts">
    import { ref, provide, computed, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { getAssets, getAsset, getAssetTotal } from '@/views/AssetsManagement/composables/assets';
    import { useAuthStore } from '@/store/auth';
    import { getForecast } from '@/views/Dashboards/composables/weather';
    import { socket } from '@/socket';
    import IoImg from '@/components/ioImg.vue';
    import moment from 'moment';
    import Chart from '@/components/charts/Chart.vue';
    import { io } from 'socket.io-client';

    const useAuth = useAuthStore();
    const token = useAuth.token;
    const user = useAuth.user;
    //weather
    const weather = ref();
    const done = ref(false);

    const $t = useI18n().t;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('dashboards.title'),
            },
            {
                title: $t('dashboards.environment.title'),
                to: { name: 'EnvironmentDashboard' },
            },
        ];
    });

    const isLoading = ref(true);

    const forecast = async () => {
        const currentTime = moment();
        const arrivalTime = currentTime.add(1, 'minutes');
        try {
            await getForecast({
                arrivalTime: arrivalTime.format('YYYY-MM-DD[T]HH:mm:ss'),
            })
                .then((response) => {
                    weather.value = response.data[0].forecast[0];
                })
                .finally(() => {
                    done.value = true;
                    isLoading.value = false;
                });
        } catch (error) {
            console.log(error);
        }
    };

    forecast();
</script>

<style scoped></style>
