<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <div class="tw-flex tw-gap-x-2">
                    <v-btn
                        elevation="0"
                        density="compact"
                        height="33"
                        width="33"
                        min-width="33"
                        rounded="lg"
                        color="primary"
                        @click="$router.go(-1)">
                        <v-icon class="tw-text-2xl"> mdi-chevron-left </v-icon>
                    </v-btn>
                    <p class="tw-text-2xl tw-font-bold">{{ $t('settings.company.structure.create') }}</p>
                </div>
                <Breadcrumbs
                    class="tw-mt-1 tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <v-row class="tw-py-4">
            <v-col cols="10">
                <v-btn
                    elevation="0"
                    density="compact"
                    height="auto"
                    class="tw-mr-3 tw-h-10"
                    rounded="lg"
                    :class="{ 'tw-bg-primary tw-text-white': showDepartmentsInfo, 'tw-bg-gray-300': !showDepartmentsInfo }"
                    @click="toggleDepartmentsInfo">
                    <p
                        class="tw-text-gray-600"
                        :class="{ 'tw-text-white': showDepartmentsInfo }">
                        {{ $t('settings.company.structure.form.department') }}
                    </p>
                </v-btn>
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-mr-3 tw-h-10"
                    rounded="lg"
                    :class="{ 'tw-bg-primary tw-text-white': showSectionsInfo, 'tw-bg-gray-300': !showSectionsInfo }"
                    @click="toggleSectionsInfo"
                    ><p
                        class="tw-text-gray-600"
                        :class="{ 'tw-text-white': showSectionsInfo }">
                        {{ $t('settings.company.structure.form.sections') }}
                    </p></v-btn
                >
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-h-10"
                    rounded="lg"
                    :class="{ 'tw-bg-primary tw-text-white': showSubSectionsInfo, 'tw-bg-gray-300': !showSubSectionsInfo }"
                    @click="toggleSubsectionsInfo">
                    <p
                        class="tw-text-gray-600"
                        :class="{ 'tw-text-white': showSubSectionsInfo }">
                        {{ $t('settings.company.structure.form.subSections') }}
                    </p>
                </v-btn>
            </v-col>
            <!-- <v-col class="tw-flex tw-justify-end">
                <v-btn
                    :loading="isLoading"
                    elevation="0"
                    density="compact"
                    class="tw-h-10"
                    rounded="lg"
                    color="primary"
                    @click="onSubmit">
                    <p class="tw-text-white">{{ $t('shared.save') }}</p></v-btn
                >
            </v-col> -->
        </v-row>
        <v-form ref="form">
            <!-- Departments -->
            <v-row v-if="showDepartmentsInfo">
                <!-- NAME -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.name')"
                        v-model="department.name">
                    </v-text-field>
                </v-col>
                <!-- PHONE -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.phone')"
                        v-model="department.phone">
                    </v-text-field>
                </v-col>
                <!-- EMAIL -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        type="email"
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.email')"
                        v-model="department.email">
                    </v-text-field>
                </v-col>
                <!-- COMPANY -->
                <v-col v-bind="colInputBinds">
                    <autocomplete-companies
                        v-model="department.company"
                        :companies="company.id ? [company.id as number] : []"
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.company')">
                    </autocomplete-companies>
                </v-col>
                <!-- IMAGE -->
                <v-col v-bind="colInputBinds">
                    <div class="tw-flex tw-flex-col tw-gap-4">
                        <v-file-input
                            v-model="departmentImage"
                            variant="solo"
                            flat
                            bg-color="gray-200"
                            rounded="lg"
                            center-affix
                            clearable
                            density="compact"
                            prepend-icon=""
                            :label="$t('assets-management.assets.form.loadImage')"
                            show-size
                            @update:model-value="onDepartmentImageChanged"
                            accept="image/*">
                        </v-file-input>
                        <v-img
                            v-if="imageSrc"
                            :src="imageSrc"
                            max-width="30rem"
                            contain></v-img>
                    </div>
                </v-col>
                <!-- NOTES -->
                <v-col cols="12">
                    <v-textarea
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.notes')"
                        v-model="department.notes"></v-textarea>
                </v-col>
                <v-col class="tw-flex tw-justify-end">
                    <v-btn
                        :loading="isLoading"
                        elevation="0"
                        density="compact"
                        class="tw-h-10"
                        rounded="lg"
                        color="primary"
                        @click="addDepartment()">
                        <p class="tw-text-white">{{ $t('settings.company.structure.form.addDepartment') }}</p></v-btn
                    >
                </v-col>
            </v-row>
            <!-- Sections -->
            <v-row v-if="showSectionsInfo">
                <!-- DEPARTMENT -->
                <v-col v-bind="colInputBinds">
                    <autocomplete-departments
                        v-model="department.id"
                        :companies="company.id ? [company.id as number] : []"
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.department')">
                    </autocomplete-departments>
                </v-col>
                <!-- NAME -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.name')"
                        v-model="section.name">
                    </v-text-field>
                </v-col>
                <!-- PHONE -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.phone')"
                        v-model="section.phone">
                    </v-text-field>
                </v-col>
                <!-- EMAIL -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        type="email"
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.email')"
                        v-model="section.email">
                    </v-text-field>
                </v-col>
                <!-- ADDRESS -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.address')"
                        v-model="section.address">
                    </v-text-field>
                </v-col>
                <!-- ADDRESS 2 -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.addressTwo')"
                        v-model="section.address2">
                    </v-text-field>
                </v-col>
                <!-- ZIP -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.zip')"
                        v-model="section.zip">
                    </v-text-field>
                </v-col>
                <!-- CITY -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.city')"
                        v-model="section.city">
                    </v-text-field>
                </v-col>
                <!-- STATE -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.state')"
                        v-model="section.state">
                    </v-text-field>
                </v-col>
                <!-- COUNTRY -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.country')"
                        v-model="section.country">
                    </v-text-field>
                </v-col>
                <!-- LATITUDE -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.latitude')"
                        v-model="section.latitude">
                    </v-text-field>
                </v-col>
                <!-- LONGITUDE -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.longitude')"
                        v-model="section.longitude">
                    </v-text-field>
                </v-col>
                <!-- CURRENCY -->
                <!-- IMAGE -->
                <v-col v-bind="colInputBinds">
                    <div class="tw-flex tw-flex-col tw-gap-4">
                        <v-file-input
                            v-model="sectionImage"
                            variant="solo"
                            flat
                            bg-color="gray-200"
                            rounded="lg"
                            center-affix
                            clearable
                            density="compact"
                            prepend-icon=""
                            :label="$t('assets-management.assets.form.loadImage')"
                            show-size
                            @update:model-value="onSectionImageChanged"
                            accept="image/*">
                        </v-file-input>
                        <v-img
                            v-if="imageSrc"
                            :src="imageSrc"
                            max-width="30rem"
                            contain></v-img>
                    </div>
                </v-col>
                <v-col class="tw-flex tw-justify-end">
                    <v-btn
                        :loading="isLoading"
                        elevation="0"
                        density="compact"
                        class="tw-h-10"
                        rounded="lg"
                        color="primary"
                        @click="addSection()">
                        <p class="tw-text-white">{{ $t('settings.company.structure.form.addSection') }}</p></v-btn
                    >
                </v-col>
            </v-row>
            <!-- SubSections -->
            <v-row v-if="showSubSectionsInfo">
                <!-- SECTION -->
                <v-col v-bind="colInputBinds">
                    <autocomplete-locations
                        v-model="section.id"
                        :companies="company.id ? [company.id as number] : []"
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.section')">
                    </autocomplete-locations>
                </v-col>
                <!-- NAME -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.name')"
                        v-model="subSection.name">
                    </v-text-field>
                </v-col>
                <!-- PHONE -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.phone')"
                        v-model="subSection.phone">
                    </v-text-field>
                </v-col>
                <!-- EMAIL -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        type="email"
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.email')"
                        v-model="subSection.email">
                    </v-text-field>
                </v-col>
                <!-- ADDRESS -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.address')"
                        v-model="subSection.address">
                    </v-text-field>
                </v-col>
                <!-- ADDRESS 2 -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.addressTwo')"
                        v-model="subSection.address2">
                    </v-text-field>
                </v-col>
                <!-- ZIP -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.zip')"
                        v-model="subSection.zip">
                    </v-text-field>
                </v-col>
                <!-- CITY -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.city')"
                        v-model="subSection.city">
                    </v-text-field>
                </v-col>
                <!-- STATE -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.state')"
                        v-model="subSection.state">
                    </v-text-field>
                </v-col>
                <!-- COUNTRY -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.country')"
                        v-model="subSection.country">
                    </v-text-field>
                </v-col>
                <!-- LATITUDE -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.latitude')"
                        v-model="subSection.latitude">
                    </v-text-field>
                </v-col>
                <!-- LONGITUDE -->
                <v-col v-bind="colInputBinds">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="gray-200"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('settings.company.structure.form.longitude')"
                        v-model="subSection.longitude">
                    </v-text-field>
                </v-col>
                <!-- CURRENCY -->
                <!-- IMAGE -->
                <v-col v-bind="colInputBinds">
                    <div class="tw-flex tw-flex-col tw-gap-4">
                        <v-file-input
                            v-model="subSectionImage"
                            variant="solo"
                            flat
                            bg-color="gray-200"
                            rounded="lg"
                            center-affix
                            clearable
                            density="compact"
                            prepend-icon=""
                            :label="$t('assets-management.assets.form.loadImage')"
                            show-size
                            @update:model-value="onSubSectionImageChanged"
                            accept="image/*">
                        </v-file-input>
                        <v-img
                            v-if="imageSrc"
                            :src="imageSrc"
                            max-width="30rem"
                            contain></v-img>
                    </div>
                </v-col>
                <v-col class="tw-flex tw-justify-end">
                    <v-btn
                        :loading="isLoading"
                        elevation="0"
                        density="compact"
                        class="tw-h-10"
                        rounded="lg"
                        color="primary"
                        @click="addSubSection()">
                        <p class="tw-text-white">{{ $t('settings.company.structure.form.addSubSection') }}</p></v-btn
                    >
                </v-col>
            </v-row>
        </v-form>
    </div>
    <v-overlay
        :model-value="isLoading"
        class="tw-items-center tw-justify-center"
        persistent>
        <v-progress-circular
            indeterminate
            size="64"
            color="primary"></v-progress-circular>
    </v-overlay>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import AutocompleteCompanies from '@/views/AssetsManagement/Companies/AutocompleteCompanies.vue';
    import AutocompleteDepartments from '@/views/Settings/Company/AutocompleteDepartments.vue';
    import AutocompleteLocations from '@/views/Settings/Company/AutocompleteLocations.vue';
    import { ContentType } from '@/contentTypes';
    import useRules from '@/composables/rules';
    import { createDepartment } from '@/views/Settings/composables/department';
    import { createLocation } from '@/views/AssetsManagement/composables/locations';
    import { uploadFiles } from '@/composables/upload';
    import { getErrorKey } from '@/composables/error';
    import { useRoute, useRouter } from 'vue-router';
    import { useAuthStore } from '@/store/auth';
    import { useMedia, parseMediaToFile } from '@/composables/useMedia';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';
    import IoImg from '@/components/ioImg.vue';

    const useAuth = useAuthStore();
    const user = useAuth.user;
    const company = useAuth.getActiveCompany;

    const isLoading = ref(false);
    const rules = useRules();
    const form = ref();
    const $route = useRoute();
    const $router = useRouter();
    const $alert = useAlert();
    const $t = useI18n().t;

    const showDepartmentsInfo = ref(true);
    const showSubSectionsInfo = ref(false);
    const showSectionsInfo = ref(false);

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('settings.title'),
            },
            {
                title: $t('settings.company.structure.title'),
                to: { name: 'Structures' },
            },
            {
                title: $t('settings.company.structure.create'),
            },
        ];
    });

    const department = ref<ContentType<'Department'>>({
        name: '',
        phone: '',
        email: '',
        company: null,
        notes: '',
    });

    const section = ref<ContentType<'Location'>>({
        name: '',
        phone: '',
        email: '',
        address: '',
        address2: '',
        zip: '',
        city: '',
        state: '',
        country: '',
        latitude: null,
        longitude: null,
        department: null,
    });

    const subSection = ref<ContentType<'Location'>>({
        name: '',
        phone: '',
        email: '',
        address: '',
        address2: '',
        zip: '',
        city: '',
        state: '',
        country: '',
        latitude: null,
        longitude: null,
        parent: null,
        department: null,
    });

    const departmentImage = ref<File[]>();
    const sectionImage = ref<File[]>();
    const subSectionImage = ref<File[]>();

    const hasChangedDepartmentImage = ref(false);
    const hasChangedSectionImage = ref(false);
    const hasChangedSubSectionImage = ref(false);

    const imageSrc = computed(() => {
        if (departmentImage.value && departmentImage.value.length > 0) {
            return URL.createObjectURL(departmentImage.value[0]);
        }
        if (sectionImage.value && sectionImage.value.length > 0) {
            return URL.createObjectURL(sectionImage.value[0]);
        }
        if (subSectionImage.value && subSectionImage.value.length > 0) {
            return URL.createObjectURL(subSectionImage.value[0]);
        }

        return '';
    });

    const colInputBinds = {
        cols: 12,
        lg: 4,
        md: 6,
    };

    async function addDepartment() {
        //avoid sending id to the server (id is coming from the autocomplete component)
        if (department?.value?.id) department.value.id = null;

        isLoading.value = true;
        const func = createDepartment(department.value);
        const { data } = await func;
        try {
            if (departmentImage.value && hasChangedDepartmentImage) {
                uploadImage(data);
            }
        } catch (error) {
            $alert.showAlert({
                text: $t(getErrorKey(error, 'settings.company.structure.errors')),
                title: $t('settings.company.structure.errors.uploading-image'),
                type: 'error',
                timeout: 0,
            });
        }
        $alert.showAlert({
            title: $t('settings.company.structure.success.create'),
            type: 'success',
            timeout: 5000,
        });
        //reset department object after successfull submit
        department.value = {
            name: '',
            phone: '',
            email: '',
            company: null,
            notes: '',
        };
        departmentImage.value = [];
        isLoading.value = false;
    }

    async function addSection() {
        //add department id to the section object, so we can relate the section to the department
        section.value.department = department.value.id;

        //also avoid sending id to the server (id is coming from the autocomplete component)
        if (section?.value?.id) section.value.id = null;

        isLoading.value = true;
        const func = createLocation(section.value);
        const { data } = await func;
        try {
            if (sectionImage.value && hasChangedSectionImage) {
                uploadImage(data);
            }
        } catch (error) {
            $alert.showAlert({
                text: $t(getErrorKey(error, 'settings.company.structure.errors')),
                title: $t('settings.company.structure.errors.uploading-image'),
                type: 'error',
                timeout: 0,
            });
        }
        $alert.showAlert({
            title: $t('settings.company.structure.success.create'),
            type: 'success',
            timeout: 5000,
        });
        //reset section object after successfull submit
        section.value = {
            name: '',
            phone: '',
            email: '',
            address: '',
            address2: '',
            zip: '',
            city: '',
            state: '',
            country: '',
            latitude: null,
            longitude: null,
            parent: null,
        };
        sectionImage.value = [];
        isLoading.value = false;
    }

    async function addSubSection() {
        //add section id to the subSection object, so we can relate the subSection to the section
        subSection.value.parent = section.value.id;

        isLoading.value = true;
        const func = createLocation(subSection.value);
        const { data } = await func;
        try {
            if (subSectionImage.value && hasChangedSubSectionImage) {
                uploadImage(data);
            }
        } catch (error) {
            $alert.showAlert({
                text: $t(getErrorKey(error, 'settings.company.structure.errors')),
                title: $t('settings.company.structure.errors.uploading-image'),
                type: 'error',
                timeout: 0,
            });
        }
        $alert.showAlert({
            title: $t('settings.company.structure.success.create'),
            type: 'success',
            timeout: 5000,
        });
        //reset subSection object after successfull submit
        subSection.value = {
            name: '',
            phone: '',
            email: '',
            address: '',
            address2: '',
            zip: '',
            city: '',
            state: '',
            country: '',
            latitude: null,
            longitude: null,
            parent: null,
        };
        subSectionImage.value = [];
        isLoading.value = false;
    }

    async function uploadImage(data: any) {
        if (departmentImage.value && hasChangedDepartmentImage) {
            await uploadFiles({
                files: departmentImage.value,
                refId: data.data.id,
                ref: 'api::department.department',
                field: 'image',
            });
        }
        if (sectionImage.value && hasChangedSectionImage) {
            await uploadFiles({
                files: sectionImage.value,
                refId: data.data.id,
                ref: 'api::location.location',
                field: 'image',
            });
        }
        if (subSectionImage.value && hasChangedSubSectionImage) {
            await uploadFiles({
                files: subSectionImage.value,
                refId: data.data.id,
                ref: 'api::location.location',
                field: 'image',
            });
        }
    }

    function toggleDepartmentsInfo() {
        if (!showDepartmentsInfo.value) showDepartmentsInfo.value = !showDepartmentsInfo.value;
        if (showDepartmentsInfo.value) {
            showSubSectionsInfo.value = false;
            showSectionsInfo.value = false;
        }
    }

    function toggleSubsectionsInfo() {
        if (!showSubSectionsInfo.value) showSubSectionsInfo.value = !showSubSectionsInfo.value;
        if (showSubSectionsInfo.value) {
            showDepartmentsInfo.value = false;
            showSectionsInfo.value = false;
        }
    }

    function toggleSectionsInfo() {
        if (!showSectionsInfo.value) showSectionsInfo.value = !showSectionsInfo.value;
        if (showSectionsInfo.value) {
            showDepartmentsInfo.value = false;
            showSubSectionsInfo.value = false;
        }
    }

    function onDepartmentImageChanged() {
        hasChangedDepartmentImage.value = true;
    }
    function onSectionImageChanged() {
        hasChangedSectionImage.value = true;
    }
    function onSubSectionImageChanged() {
        hasChangedSubSectionImage.value = true;
    }
</script>

<style>
    .e-panels .v-expansion-panel-text__wrapper {
        padding-inline: 0 !important;
    }
</style>
