<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('settings.company.structure.title') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <div
            v-if="errorMessage"
            class="">
            <v-alert
                elevation="0"
                type="error"
                variant="tonal"
                density="compact"
                >{{ errorMessage }}</v-alert
            >
        </div>
        <v-row class="tw-justify-between tw-py-4">
            <v-col
                class="tw-flex tw-gap-x-4"
                cols="12"
                lg="7">
                <v-text-field
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('shared.search')"
                    v-model="search">
                    <template #append-inner>
                        <v-icon class="tw-text-gray">mdi-magnify</v-icon>
                    </template>
                </v-text-field>
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-h-10 lg:tw-w-auto"
                    rounded="lg"
                    color="gray-300"
                    :to="{ name: 'CreateStructure' }"
                    ><v-icon class="tw-pr-2 tw-text-xl tw-text-black">mdi-filter</v-icon>
                    {{ $t('shared.filters') }}
                </v-btn>
            </v-col>
            <v-col
                class="tw-order-first tw-mb-2 lg:tw-order-last lg:tw-mb-0 lg:tw-flex lg:tw-justify-end"
                cols="12"
                md="auto">
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-mb-2 tw-mr-2 tw-h-10 xs:tw-mb-0"
                    rounded="lg"
                    color="primary"
                    :to="{ name: 'CreateStructure' }"
                    ><v-icon class="tw-pr-2 tw-text-xl tw-text-white">mdi-plus</v-icon>
                    <p class="tw-text-white">
                        {{ $t('settings.company.structure.create') }}
                    </p>
                </v-btn>
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-h-10"
                    rounded="lg"
                    color="primary"
                    @click="handleStructureImport"
                    ><v-icon class="tw-pr-2 tw-text-xl tw-text-white">mdi-download</v-icon>
                    <p class="tw-text-white">
                        {{ $t('settings.company.structure.import') }}
                    </p>
                    <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        @change="onFileChanged" />
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table-server
            v-model:expanded="expanded"
            show-expand
            hover
            density="comfortable"
            :loading="isLoading"
            :loading-text="$t('shared.loading')"
            :headers="headers"
            :items-per-page-options="[10, 25, 50, 100]"
            v-model:items-per-page="pageSize"
            :no-data-text="$t('settings.company.structure.errors.not_found')"
            v-model:page="page"
            :items-length="total"
            show-select
            @update:options="onOptionsUpdated"
            v-model="itemsSelected"
            :items="departments"
            height="calc(100vh - 280px)"
            :fixed-header="true">
            <template #item.image="{ value }">
                <io-img
                    format="thumbnail"
                    :media="value"
                    content-type="Department"
                    width="75"
                    height="75"
                    contain></io-img>
            </template>
            <template #item.actions="{ item }">
                <div class="tw-flex tw-gap-1">
                    <!-- <v-btn
                        color="navy"
                        size="x-small"
                        class="tw-rounded-md"
                        icon
                        :to="{ name: 'AM-CloneAsset', params: { id: item.id } }">
                        <v-icon class="tw-text-xl tw-text-white">mdi-card-multiple-outline</v-icon>
                    </v-btn> -->
                    <!-- <v-btn
                        color="blue-400"
                        size="x-small"
                        class="tw-rounded-md"
                        icon
                        :to="{ name: 'AM-Asset', params: { id: item.id } }">
                        <v-icon class="tw-text-xl tw-text-white">mdi-eye</v-icon>
                    </v-btn> -->
                    <v-btn
                        color="yellow-300"
                        icon
                        size="x-small"
                        class="tw-rounded-md"
                        :to="{ name: 'EditStructure', params: { id: item.id } }">
                        <v-icon class="tw-text-xl tw-text-white">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        color="red-400"
                        size="x-small"
                        class="tw-rounded-md"
                        icon
                        @click="executeAction(item.id)">
                        <v-icon class="tw-text-xl tw-text-white">mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
            <!-- expanded row -->
            <template v-slot:expanded-row="{ columns, item }">
                <tr v-if="item.locations?.length > 0">
                    <td :colspan="columns.length">
                        <div class="tw-py-4">
                            <v-row
                                class="tw-items-center"
                                v-for="(location, i) in item?.locations"
                                :key="i">
                                <v-col
                                    align="center"
                                    cols="1">
                                    <io-img
                                        format="thumbnail"
                                        :media="location.image"
                                        content-type="Location"
                                        width="75"
                                        height="75"
                                        contain></io-img>
                                </v-col>
                                <v-col
                                    align="center"
                                    cols="">
                                    <p class="tw-text-secondary tw-text-sm tw-font-semibold">{{ $t('settings.company.structure.fields.name') }}</p>
                                    <p class="tw-text-secondary tw-text-sm">{{ location.name }}</p>
                                </v-col>
                                <v-col
                                    align="center"
                                    cols="">
                                    <p class="tw-text-secondary tw-text-sm tw-font-semibold">{{ $t('settings.company.structure.fields.email') }}</p>
                                    <p class="tw-text-secondary tw-text-sm">{{ location.email }}</p>
                                </v-col>
                                <v-col
                                    align="center"
                                    cols="">
                                    <p class="tw-text-secondary tw-text-sm tw-font-semibold">{{ $t('settings.company.structure.fields.phone') }}</p>
                                    <p class="tw-text-secondary tw-text-sm">{{ location.phone }}</p>
                                </v-col>
                                <v-col
                                    align="center"
                                    cols="">
                                    <p class="tw-text-secondary tw-text-sm tw-font-semibold">{{ $t('settings.company.structure.fields.latitude') }}</p>
                                    <p class="tw-text-secondary tw-text-sm">{{ location.latitude }}</p>
                                </v-col>
                                <v-col
                                    align="center"
                                    cols="">
                                    <p class="tw-text-secondary tw-text-sm tw-font-semibold">{{ $t('settings.company.structure.fields.longitude') }}</p>
                                    <p class="tw-text-secondary tw-text-sm">{{ location.longitude }}</p>
                                </v-col>
                                <v-col
                                    align="center"
                                    cols="">
                                    <v-chip
                                        size="small"
                                        class="tw-text-primary-900">
                                        {{ $t('settings.company.structure.fields.section') }}
                                    </v-chip>
                                </v-col>
                                <v-row
                                    class="tw-w-full tw-items-center tw-px-3 tw-py-4"
                                    v-if="location?.children.length > 0"
                                    v-for="(children, k) in location?.children"
                                    :key="k">
                                    <v-col
                                        cols="1"
                                        align="center">
                                        <io-img
                                            format="thumbnail"
                                            :media="children.image"
                                            content-type="Location"
                                            width="75"
                                            height="75"
                                            contain></io-img>
                                    </v-col>
                                    <v-col
                                        align="center"
                                        cols="">
                                        <p class="tw-text-secondary tw-text-sm tw-font-semibold">{{ $t('settings.company.structure.fields.name') }}</p>
                                        <p class="tw-text-secondary tw-text-sm">{{ children.name }}</p>
                                    </v-col>
                                    <v-col
                                        align="center"
                                        cols="">
                                        <p class="tw-text-secondary tw-text-sm tw-font-semibold">{{ $t('settings.company.structure.fields.email') }}</p>
                                        <p class="tw-text-secondary tw-text-sm">{{ children.email }}</p>
                                    </v-col>
                                    <v-col
                                        align="center"
                                        cols="">
                                        <p class="tw-text-secondary tw-text-sm tw-font-semibold">{{ $t('settings.company.structure.fields.phone') }}</p>
                                        <p class="tw-text-secondary tw-text-sm">{{ children.phone }}</p>
                                    </v-col>
                                    <v-col
                                        align="center"
                                        cols="">
                                        <p class="tw-text-secondary tw-text-sm tw-font-semibold">{{ $t('settings.company.structure.fields.latitude') }}</p>
                                        <p class="tw-text-secondary tw-text-sm">{{ children.latitude }}</p>
                                    </v-col>
                                    <v-col
                                        align="center"
                                        cols="">
                                        <p class="tw-text-secondary tw-text-sm tw-font-semibold">{{ $t('settings.company.structure.fields.longitude') }}</p>
                                        <p class="tw-text-secondary tw-text-sm">{{ children.longitude }}</p>
                                    </v-col>
                                    <v-col
                                        align="center"
                                        cols="">
                                        <v-chip
                                            size="small"
                                            class="tw-text-primary-100">
                                            {{ $t('settings.company.structure.fields.subSection') }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                                <v-divider v-if="i !== item?.locations?.length - 1"></v-divider>
                            </v-row>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table-server>
    </div>
    <ConfirmDelete
        v-model="isDeleting"
        @action="onDelete" />
</template>

<script setup lang="ts">
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import { ContentType } from '@/contentTypes';
    import { computed, ref, watch } from 'vue';
    import { getDepartments, deleteDepartments, deleteDepartment } from '@/views/Settings/composables/department';
    import { VDataTableOptions } from '@/types';
    import { getErrorKey } from '@/composables/error';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from '@/store/auth';
    import IoImg from '@/components/ioImg.vue';
    import ConfirmDelete from '@/components/ConfirmDelete.vue';

    const useAuth = useAuthStore();
    const user = useAuth.user;
    const activeCompany = useAuth.activeCompany.id;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('settings.title'),
            },
            {
                title: $t('settings.company.structure.title'),
                to: { name: 'Structures' },
            },
        ];
    });

    const $t = useI18n().t;

    const search = ref('');

    const departments = ref<ContentType<'Asset'>[]>([]);
    const expanded = ref<number[]>([]);
    const itemsSelected = ref<number[]>([]);
    const itemToDelete = ref<number | null>(null);
    const isLoading = ref(true);
    const pageCount = ref(0);
    const pageSize = ref(10);
    const errorMessage = ref('');
    const total = ref(0);
    const page = ref(1);
    const sort = ref<VDataTableOptions['sortBy']>([
        {
            key: 'id',
            order: 'desc',
        },
    ]);
    const firstUpdate = ref(true);
    const isDeleting = ref(false);

    const uploader = ref<HTMLInputElement | null>(null);

    const actionOptions = [
        {
            text: $t('shared.delete'),
            value: 'delete',
        },
    ];
    const actionSelected = ref('');

    const headers = [
        {
            // title: $t('settings.company.structure.fields.image'),
            sortable: false,
            key: 'image',
        },
        {
            title: $t('settings.company.structure.fields.name'),
            sortable: true,
            key: 'name',
        },
        {
            title: $t('settings.company.structure.fields.email'),
            sortable: true,
            key: 'email',
        },
        {
            title: $t('settings.company.structure.fields.phone'),
            sortable: true,
            key: 'phone',
        },
        {
            title: $t('settings.company.structure.fields.notes'),
            sortable: true,
            key: 'notes',
        },
        {
            sortable: false,
            key: 'actions',
        },
    ] as VDataTableServer['headers'];

    function onOptionsUpdated(options: VDataTableOptions) {
        if (firstUpdate.value) {
            firstUpdate.value = false;
            return;
        }
        page.value = options.page;
        pageSize.value = options.itemsPerPage;
        sort.value = options.sortBy;
        init();
    }

    async function init() {
        isLoading.value = true;
        errorMessage.value = '';
        try {
            const { data } = await getDepartments({
                filters: {
                    $or: [
                        {
                            name: {
                                $containsi: search.value,
                            },
                        },
                        {
                            phone: {
                                $containsi: search.value,
                            },
                        },
                        {
                            email: {
                                $containsi: search.value,
                            },
                        },
                    ],
                    company: {
                        id: {
                            $in: activeCompany,
                        },
                    },
                },
                populate: {
                    manager: {
                        fields: ['id'],
                    },
                    locations: {
                        populate: {
                            children: {
                                populate: {
                                    image: true,
                                }
                            },
                            image: true,
                        },
                    },
                    company: {
                        fields: ['id'],
                    },
                    image: true,
                },
                pagination: {
                    page: page.value,
                    pageSize: pageSize.value,
                },
                sort: sort.value.map((s) => `${s.key}:${s.order}`),
            });
            departments.value = data.data;
            total.value = data.meta.pagination.total;
            pageCount.value = data.meta.pagination.pageCount;
        } catch (error) {
            const messageKey = getErrorKey(error, `settings.company.structure.errors`);
            errorMessage.value = $t(messageKey);
        } finally {
            isLoading.value = false;
        }
    }

    function executeAction(id: any) {
        if (id) (isDeleting.value = true), (itemToDelete.value = departments.value.find((a) => a.id === id)?.id);
        if (!id) {
            if (actionSelected.value === 'delete') isDeleting.value = true;
        }
    }

    async function onDelete(action: string) {
        if (action !== 'yes') return;
        isLoading.value = true;
        try {
            if (itemToDelete.value) await deleteDepartment(itemToDelete.value);
            if (itemsSelected.value.length > 0) await deleteDepartments(itemsSelected.value);
            init();
        } catch (error) {
            const messageKey = getErrorKey(error, `settings.company.structure.errors`);
            errorMessage.value = $t(messageKey);
        } finally {
            itemToDelete.value = null;
            isLoading.value = false;
        }
    }

    // Asset Upload
    function handleStructureImport() {
        if (uploader.value) {
            uploader.value.click();
        }
    }
    function onFileChanged(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
            const file = target.files[0];
            console.log(file);
        }
    }

    watch(search, () => {
        init();
    });

    init();
</script>
<style scoped></style>
<style>
    .v-data-table__td--expanded-row button {
        box-shadow: none !important;
    }
</style>
