<template>
    <div>
        <v-row v-resize="onResize">
            <v-col cols="12">
                <v-card
                    ref="energyFlowCardElement"
                    variant="flat"
                    class="mx-auto tw-pr-2 tw-pt-2">
                    <template v-slot:prepend>
                        <v-icon class="tw-rounded-md tw-bg-primary-100 tw-bg-opacity-100 tw-p-4 tw-text-primary">mdi-swap-vertical</v-icon>
                    </template>
                    <template v-slot:title>
                        <p class="tw-text-base tw-font-semibold">{{ companyDomain == 'ict' ?$t('dashboards.energy.energyFlowCard.title') : $t('dashboards.industry.industryFlowCard.title') }}</p>
                    </template>
                    <!-- <v-card-title class="tw-text-base tw-font-semibold"> {{ $t('dashboards.energy.energyFlowCard.title') }} </v-card-title> -->
                    <v-card-text>
                        <div class="tw-mt-2">
                            <!-- First Row -->
                            <v-row class="tw-h-48">
                                <!-- Non Fossil -->
                                <v-col>
                                    <p class="tw-w-16 tw-text-center tw-text-[10px] tw-text-gray-800 xs:tw-w-24 xs:tw-text-sm">{{ $t('dashboards.energy.energyFlowCard.labels.nonFossil') }}</p>
                                    <div
                                        id="non-fossil"
                                        class="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-text-center tw-text-[10px] tw-text-green xs:tw-h-24 xs:tw-w-24 xs:tw-text-sm">
                                        <div class="tw-flex tw-flex-col tw-items-center">
                                            <v-icon>mdi-pine-tree</v-icon>
                                            <p class="tw-font-semibold tw-text-black">
                                                {{ (parseInt(getEnergyFlowData('energy').state) * (100 - parseInt(getEnergyFlowData('fossilFuelPercentage').state))) / 100 }}
                                                {{ getEnergyFlowData('energy').unitOfMeasurement }}
                                            </p>
                                        </div>
                                    </div>
                                </v-col>
                                <!-- Solar -->
                                <v-col class="tw-flex tw-justify-center">
                                    <div class="tw-flex tw-flex-col">
                                        <p class="tw-w-16 tw-text-center tw-text-[10px] tw-text-gray-800 xs:tw-w-24 xs:tw-text-sm">{{ $t('dashboards.energy.energyFlowCard.labels.solar') }}</p>
                                        <div
                                            id="solar"
                                            class="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-text-center tw-text-[10px] tw-text-orange xs:tw-h-24 xs:tw-w-24 xs:tw-text-sm">
                                            <div class="tw-flex tw-flex-col tw-items-center">
                                                <v-icon class="tw-text-black">mdi-solar-power</v-icon>
                                                <p class="tw-font-semibold tw-text-black">0 W</p>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                                <!-- Qivi(ict)/Weaving(industry) -->
                                <v-col class="tw-flex tw-justify-end">
                                    <div class="tw-flex tw-flex-col">
                                        <p class="tw-w- tw-text-center tw-text-[10px] tw-text-gray-800 xs:tw-w-24 xs:tw-text-sm">{{ companyDomain == 'ict' ? $t('dashboards.energy.energyFlowCard.labels.qivi') : $t('dashboards.industry.industryFlowCard.labels.weaving') }}</p>
                                        <div
                                            id="qivi"
                                            class="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-text-center tw-text-[10px] tw-text-pink-300 xs:tw-h-24 xs:tw-w-24 xs:tw-text-sm">
                                            <div class="tw-flex tw-flex-col tw-items-center">
                                                <v-icon
                                                    v-if="companyDomain == 'ict'"
                                                    class="tw-text-black"
                                                    >mdi-motorbike-electric</v-icon
                                                >
                                                <img
                                                    v-if="companyDomain == 'industry'"
                                                    class="tw-h-5 tw-w-5"
                                                    src="/yarn.svg"
                                                    alt="" />
                                                <p class="tw-font-semibold tw-text-black">0 W</p>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <!-- Second Row -->
                            <v-row class="tw-h-48">
                                <!-- Grid -->
                                <v-col>
                                    <div
                                        id="grid"
                                        class="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-text-center tw-text-[10px] tw-text-blue xs:tw-h-24 xs:tw-w-24 xs:tw-text-sm">
                                        <div class="tw-flex tw-flex-col tw-items-center">
                                            <v-icon class="tw-text-black">mdi-transmission-tower</v-icon>
                                            <div class="tw-flex tw-items-center tw-font-semibold tw-text-purple">
                                                <v-icon>mdi-arrow-left-thin</v-icon>
                                                <p>0 W</p>
                                            </div>
                                            <div class="tw-flex tw-items-center tw-font-semibold tw-text-blue-300">
                                                <v-icon>mdi-arrow-right-thin</v-icon>
                                                <p>{{ getEnergyFlowData('energy').state }} {{ getEnergyFlowData('energy').unitOfMeasurement }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="tw-w-16 tw-text-center tw-text-[10px] tw-text-gray-800 xs:tw-w-24 xs:tw-text-sm">{{ $t('dashboards.energy.energyFlowCard.labels.grid') }}</p>
                                </v-col>
                                <!-- Home(ict)/Factory(industry) -->
                                <v-col class="tw-flex tw-justify-end">
                                    <div
                                        id="home"
                                        class="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-text-center tw-text-[10px] tw-text-black xs:tw-h-24 xs:tw-w-24 xs:tw-text-sm">
                                        <div class="tw-flex tw-flex-col tw-items-center">
                                            <v-icon v-if="companyDomain == 'ict'" class="tw-text-black">mdi-home</v-icon>
                                            <v-icon v-if="companyDomain == 'industry'" class="tw-text-black">mdi-factory</v-icon>
                                            <p class="tw-font-semibold tw-text-black">{{ getEnergyFlowData('energy').state }} {{ getEnergyFlowData('energy').unitOfMeasurement }}</p>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <!-- Third Row -->
                            <v-row class="">
                                <!-- Battery -->
                                <v-col
                                    offset="4"
                                    class="tw-flex tw-justify-center">
                                    <div class="tw-flex tw-flex-col">
                                        <div
                                            id="battery"
                                            class="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-text-center tw-text-[10px] tw-text-pink xs:tw-h-24 xs:tw-w-24 xs:tw-text-sm">
                                            <div class="tw-flex-col tw-items-center">
                                                <div class="tw-flex tw-justify-center tw-pt-[5px] xs:tw-flex-col xs:tw-items-center xs:tw-pt-0">
                                                    <p class="tw-font-semibold tw-text-black">{{ batteryPercentage }} %</p>
                                                    <v-icon
                                                        v-if="batteryPercentage <= 25"
                                                        class="tw-text-black"
                                                        >mdi-battery-low</v-icon
                                                    >
                                                    <v-icon
                                                        v-if="batteryPercentage > 25 && batteryPercentage <= 75"
                                                        class="tw-text-black"
                                                        >mdi-battery-medium</v-icon
                                                    >
                                                    <v-icon
                                                        v-if="batteryPercentage > 75"
                                                        class="tw-text-black"
                                                        >mdi-battery-high</v-icon
                                                    >
                                                </div>
                                                <div class="tw-flex tw-items-center tw-font-semibold tw-text-pink-300">
                                                    <v-icon>mdi-arrow-down-thin</v-icon>
                                                    <p>0 W</p>
                                                </div>
                                                <div class="tw-flex tw-items-center tw-font-semibold tw-text-green-400">
                                                    <v-icon>mdi-arrow-up-thin</v-icon>
                                                    <p>0 W</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="tw-w-16 tw-text-center tw-text-[10px] tw-text-gray-800 xs:tw-w-24 xs:tw-text-sm">{{ $t('dashboards.energy.energyFlowCard.labels.battery') }}</p>
                                    </div>
                                </v-col>
                                <!-- Denim Flash(ict)/Production(industry)-->
                                <v-col class="tw-flex tw-justify-end">
                                    <div class="tw-flex tw-flex-col">
                                        <div
                                            id="denim-flash"
                                            class="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-text-center tw-text-[10px] tw-text-blue-300 xs:tw-h-24 xs:tw-w-24 xs:tw-text-sm">
                                            <div class="tw-flex tw-flex-col tw-items-center">
                                                <v-icon v-if="companyDomain == 'ict'" class="tw-text-black">mdi-car-electric</v-icon>
                                                <v-icon v-if="companyDomain == 'industry'" class="tw-text-black">mdi-robot-industrial</v-icon>
                                                <p class="tw-font-semibold tw-text-black">0 W</p>
                                            </div>
                                        </div>
                                        <p class="tw-w-16 tw-text-center tw-text-[10px] tw-text-gray-800 xs:tw-w-24 xs:tw-text-sm">{{ companyDomain == 'ict' ? 'Denim Flash' : $t('dashboards.industry.industryFlowCard.labels.production') }}</p>
                                    </div>
                                </v-col>
                            </v-row>
                            <!-- Lines -->
                            <svg id="svg">
                                <path
                                    v-for="(connection, i) in connections"
                                    :key="i"
                                    :id="`path-${i}`"
                                    :d="`M ${connection.lineCoords.x1} ${connection.lineCoords.y1} L ${connection.lineCoords.x2} ${connection.lineCoords.y2}`"></path>
                                <circle
                                    v-for="(connection, i) in connections"
                                    :key="i"
                                    :id="`circle-${i}`"
                                    :cx="connection.circleCoords.x"
                                    :cy="connection.circleCoords.y"
                                    r="7"></circle>
                            </svg>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script setup lang="ts">
    import { update } from 'lodash';
    import { ref, onMounted, watch } from 'vue';
    import { createLines, createCircles } from '@/composables/useEnergyFlow';
    import { useResizeObserver } from '@vueuse/core';
    import { useAuthStore } from '@/store/auth';

    const useAuth = useAuthStore();
    const company = useAuth.getActiveCompany;
    const companyDomain = ref(company?.domain);

    const props = defineProps({
        energyFlowData: {
            type: Object,
            required: true,
        },
    });

    const energyFlowCardElement = ref<HTMLElement | null>(null);

    const nonFossil = ref(331);
    const solar = ref(2204);
    const qivi = ref(1100);
    const gridIn = ref(662);
    const gridOut = ref(0);
    const home = ref(3476);
    const batteryPercentage = ref(100);
    const batteryIn = ref(610);
    const batteryOut = ref(0);
    const denimFlash = ref(2210);

    //energy flow connections
    const connections = [
        {
            name: 'NonFossil-Grid',
            lineCoords: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0,
            },
            circleCoords: {
                x: 0,
                y: 0,
            },
        },
        {
            name: 'Qivi-Home',
            lineCoords: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0,
            },
            circleCoords: {
                x: 0,
                y: 0,
            },
        },
        {
            name: 'Home-DenimFlash',
            lineCoords: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0,
            },
            circleCoords: {
                x: 0,
                y: 0,
            },
        },
        {
            name: 'Grid-Home',
            lineCoords: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0,
            },
            circleCoords: {
                x: 0,
                y: 0,
            },
        },
        {
            name: 'Solar-Battery',
            lineCoords: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0,
            },
            circleCoords: {
                x: 0,
                y: 0,
            },
        },
        {
            name: 'Solar-Grid',
            lineCoords: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0,
            },
            circleCoords: {
                x: 0,
                y: 0,
            },
        },
        {
            name: 'Grid-Battery',
            lineCoords: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0,
            },
            circleCoords: {
                x: 0,
                y: 0,
            },
        },
        {
            name: 'Solar-Home',
            lineCoords: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0,
            },
            circleCoords: {
                x: 0,
                y: 0,
            },
        },
        {
            name: 'Battery-Home',
            lineCoords: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0,
            },
            circleCoords: {
                x: 0,
                y: 0,
            },
        },
    ];

    const onResize = () => {
        generateEnergyFlow();
    };

    onMounted(() => {
        generateEnergyFlow();
    });

    const generateEnergyFlow = () => {
        const nonFossil = document.getElementById('non-fossil');
        const grid = document.getElementById('grid');
        const solar = document.getElementById('solar');
        const qivi = document.getElementById('qivi');
        const home = document.getElementById('home');
        const battery = document.getElementById('battery');
        const denimFlash = document.getElementById('denim-flash');

        for (let i = 0; i < connections.length; i++) {
            const path = document.getElementById(`path-${i}`);
            createLines(path, connections[i], nonFossil, grid, solar, qivi, home, battery, denimFlash);
        }
        generateCircles();
    };

    const generateCircles = () => {
        for (let i = 0; i < connections.length; i++) {
            const circle = document.getElementById(`circle-${i}`);
            const path = document.getElementById(`path-${i}`);
            const circleCoords = connections[i].circleCoords;

            createCircles(circle, path, circleCoords, connections[i]);
        }
        requestAnimationFrame(generateCircles);
    };

    // Function to generate random values for the energy flow
    const generateRandomValues = () => {
        nonFossil.value = Math.floor(Math.random() * 1000);
        solar.value = Math.floor(Math.random() * 1000);
        qivi.value = Math.floor(Math.random() * 1000);
        gridIn.value = Math.floor(Math.random() * 1000);
        gridOut.value = Math.floor(Math.random() * 1000);
        home.value = Math.max(Math.floor(Math.random() * 10000), 2500);
        batteryPercentage.value = Math.floor(Math.random() * 100);
        batteryIn.value = Math.floor(Math.random() * 1000);
        batteryOut.value = Math.floor(Math.random() * 1000);
        denimFlash.value = Math.floor(Math.random() * 1000);
    };

    // Run the function every 2 seconds, continuously
    setInterval(generateRandomValues, 2000);

    const getEnergyFlowData = (name: string) => {
        let state = props?.energyFlowData?.find((data: any) => data?.name === name)?.state;
        let unitOfMeasurement = props?.energyFlowData?.find((data: any) => data?.name === name)?.unitOfMeasurement;
        if (state == 'unavailable') state = 'N/A';
        if (unitOfMeasurement == 'unavailable') unitOfMeasurement = '';
        return { state, unitOfMeasurement };
    };

    // Resize observer, so that when sidebar is opened/closed, the energy flow card is re-rendered
    useResizeObserver(energyFlowCardElement, generateEnergyFlow);
</script>

<style scoped>
    #svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>
