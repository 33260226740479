<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('alarmist.title') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div
        v-if="!isLoading"
        class="content-container">
        <div
            v-if="errorMessage"
            class="">
            <v-alert
                elevation="0"
                type="error"
                variant="tonal"
                density="compact"
                >{{ errorMessage }}</v-alert
            >
        </div>
        <v-row>
            <v-col
                v-for="(card, index) in cardsData"
                :key="index"
                class="mx-auto tw-py-7"
                cols="12"
                md="6"
                xxl="3">
                <v-card variant="flat">
                    <template v-slot:prepend>
                        <v-icon :class="getIconAndClass(getCardData(card).entityId).class"> {{ getIconAndClass(getCardData(card).entityId).icon }} </v-icon>
                    </template>
                    <template v-slot:title>
                        <div class="tw-flex tw-justify-end">
                            <p class="tw-text-xl tw-font-semibold">{{ getCardData(card).value }}</p>
                        </div>
                    </template>
                    <v-card-actions class="!tw-p-0">
                        <v-list-item class="w-100 tw-text-sm">
                            <template v-slot:prepend>
                                <p class="tw-text-gray-600">
                                    {{ getCardData(card).name }}
                                </p>
                            </template>
                            <template v-slot:append>
                                <div class="justify-self-end !tw-p-0">
                                    <p class="tw-font-semibold">{{ getCardData(card).unitOfMeasurement }}</p>
                                </div>
                            </template>
                        </v-list-item>
                    </v-card-actions>
                    <p class="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-px-4 tw-pb-4 tw-text-xs">
                        <span class="tw-font-semibold">{{ $t('alarmist.energy.labels.lastOccurrence') }}: </span> <br />
                        {{ getCardData(card).lastOccurrence }}
                    </p>
                </v-card>
            </v-col>
        </v-row>
        <v-data-table-server
            hover
            color="primary"
            density="comfortable"
            :loading="isLoading"
            :loading-text="$t('shared.loading')"
            :headers="headers"
            :items-per-page-options="[10, 25, 50, 100]"
            v-model:items-per-page="pageSize"
            :no-data-text="$t('alarmist.errors.not_found')"
            v-model:page="page"
            :items-length="total"
            @update:options="onOptionsUpdated"
            :items="tableItems"
            height="calc(100vh - 280px)"
            :fixed-header="true">
            <template #item.data.attributes.device_class="{ value }">
                {{ value ? $t(`alarmist.energy.labels.${value}`) : 'N/A' }}
            </template>
            <template #item.criticality="{ value }">
                <div class="tw-w-16">
                    <v-chip
                        size="small"
                        class="tw-flex tw-w-full tw-justify-center"
                        :class="{ 'tw-text-red': value == 'high', 'tw-text-yellow': value == 'medium', 'tw-text-green': value == 'low' }">
                        {{ $t(`alarmist.energy.fields.${value}`) }}
                    </v-chip>
                </div>
            </template>
        </v-data-table-server>
    </div>
    <v-overlay
        :model-value="isLoading"
        class="tw-items-center tw-justify-center"
        persistent>
        <v-progress-circular
            indeterminate
            size="64"
            color="primary"></v-progress-circular>
    </v-overlay>
</template>
<script setup lang="ts">
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import { ContentType } from '@/contentTypes';
    import { ref, computed } from 'vue';
    import { useAuthStore } from '@/store/auth';
    import useIconDictionary from '@/composables/useIconDictionary';
    import { getCompany } from '@/views/AssetsManagement/composables/company';
    import { getAssets, getAsset, getAssetTotal } from '@/views/AssetsManagement/composables/assets';
    import { getAlarmist } from '@/views/Alarmist/composables/alarmist';
    import { VDataTableOptions } from '@/types';
    import { useI18n } from 'vue-i18n';
    import moment from 'moment';
    import IoImg from '@/components/ioImg.vue';
    import { io } from 'socket.io-client';
    import { isArray, sortBy } from 'lodash';

    const $t = useI18n().t;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('alarmist.title'),
            },
            {
                title: $t('alarmist.energy.title'),
                to: { name: 'EnergyAlarmist' },
            },
        ];
    });

    const useAuth = useAuthStore();
    const user = useAuth.user;
    const token = useAuth.token;
    const companyId = useAuth.activeCompany?.id;

    const useIconDict = useIconDictionary();

    const errorMessages = ref('');

    //socket
    const SOCKET_URL = import.meta.env.VITE_SOCKET_URL;

    const settings = ref();
    const assets = ref<ContentType<'Asset'>[]>([]);

    //data variables
    const cardsData = ref([]);
    const historyData = ref([]);

    const isLoading = ref(true);

    const tableItems = ref([]);
    const pageCount = ref(0);
    const pageSize = ref(10);
    const total = ref(0);
    const page = ref(1);

    const errorMessage = ref('');

    const firstUpdate = ref(true);

    const headers = [
        {
            title: $t('alarmist.energy.fields.date'),
            sortable: true,
            key: 'when',
        },
        {
            title: $t('alarmist.energy.fields.name'),
            sortable: true,
            key: 'assetName',
        },
        {
            title: $t('alarmist.energy.fields.metric'),
            sortable: true,
            key: 'data.attributes.device_class',
        },
        {
            title: $t('alarmist.energy.fields.type'),
            sortable: true,
            key: 'typology',
        },
        {
            title: $t('alarmist.energy.fields.value'),
            sortable: true,
            key: 'data.value',
            align: 'end',
        },
        {
            title: $t('alarmist.energy.fields.unit'),
            sortable: true,
            key: 'data.attributes.unit_of_measurement',
        },
        {
            title: $t('alarmist.energy.fields.location'),
            sortable: true,
            key: 'location',
        },
        {
            title: $t('alarmist.energy.fields.criticality'),
            sortable: true,
            key: 'criticality',
        },
    ] as VDataTableServer['headers'];

    //fetch company so we can get settings
    const fetchCompany = async () => {
        try {
            await getCompany(companyId, {
                populate: ['settings'],
            })
                .then((response) => {
                    settings.value = response.data.data.settings;
                })
                .finally(() => {
                    initSocket();
                });
        } catch (error) {
            errorMessage.value = error.message;
        }
    };

    //fetch assets so we can get the location
    const fetchAssets = async () => {
        errorMessages.value = '';
        try {
            await getAssets({
                populate: ['location', 'typology'],
            })
                .then((response) => {
                    assets.value = response.data.data;
                })
                .finally(() => {
                    fetchAlarmist();
                });
        } catch (error) {
            errorMessage.value = error.message;
        }
    };

    //fetch alarmist
    const fetchAlarmist = async () => {
        try {
            await getAlarmist({})
                .then((response) => {
                    response.data.forEach((element: any) => {
                        //find the alarmist in the settings and add it to the cardsData
                        if (settings.value.ioEnergyConfig.alarmist.find((item: any) => item.alarmistId == element.id)) {
                            cardsData.value.push(element);
                        }
                        //add location and criticality to history data
                        //find asset based on alarmist entity_id, so we can get the location
                        let entityId = element.trigger[0].entity_id;
                        if (isArray(entityId)) entityId = entityId[0];

                        let asset = assets.value.find((asset) => asset?.entityIdsHA?.includes(entityId));
                        if (asset) {
                            let location = asset?.location?.name ? asset.location.name : 'N/A';
                            let criticality = element.action[0].event_data.criticality;
                            element.history.forEach((history: any) => {
                                history['assetName'] = asset.name;
                                history['location'] = location;
                                history['typology'] = asset.typology?.name ? asset.typology.name : 'N/A';
                                history['criticality'] = criticality;
                            });
                            historyData.value.push(...element.history);
                        }
                    });
                    total.value = historyData.value.length;
                    pageCount.value = Math.ceil(total.value / pageSize.value);

                    //transform date to a readable format DD-MM-YYYY HH:mm:ss
                    historyData.value.forEach((element: any) => {
                        element.when = moment(element.when).format('DD-MM-YYYY HH:mm:ss');
                    });
                    //sort by date by default
                    historyData.value = sortBy(historyData.value, 'when').reverse();
                    tableItems.value = historyData.value.slice((page.value - 1) * pageSize.value, page.value * pageSize.value);
                })
                .then(() => {
                    isLoading.value = false;
                });
        } catch (error) {
            console.log(error);
            isLoading.value = false;
        }
    };

    const initSocket = async () => {
        console.log('init');
        const socket = io(SOCKET_URL, {
            reconnectionDelayMax: 10000,
            query: {
                token: token,
                companyId: companyId,
            },
        });
        socket.on('connect', () => {
            console.log('connected');
            socket.on('socket', (data: any) => {
                let event = data.event;
                let foundEntityId = cardsData.value.find((element: any) => element.trigger[0].entity_id == event?.data?.entity_id);
                if (foundEntityId) {
                    console.log(event);
                    let entityId = event.data.entity_id;
                    if (isArray(entityId)) entityId = entityId[0];
                    let asset = assets.value.find((asset) => asset?.entityIdsHA?.includes(entityId));
                    foundEntityId.history.push({
                        when: moment(event.time_fired).format('DD-MM-YYYY HH:mm:ss'),
                        assetName: asset ? asset.name : '',
                        data: {
                            value: event.data.value,
                            attributes: {
                                device_class: event.data.attributes.device_class,
                                unit_of_measurement: event.data.attributes.unit_of_measurement,
                            },
                        },
                    });
                }
            });
            socket.on('ready', (data: any) => {
                if (data == true) {
                    console.log('ready');
                    socket.emit('socket', {
                        id: 2,
                        type: 'subscribe_events',
                        event_type: 'ioenergy_alarmist',
                    });
                }
            });
        });
    };

    fetchCompany().then(() => {
        fetchAssets();
    });

    const getCardData = (card: any) => {
        try {
            let foundEntityId = cardsData.value.find((element: any) => element.trigger[0].entity_id == card.trigger[0].entity_id);
            if (foundEntityId) {
                if (foundEntityId.history.length > 0) {
                    let entityId = foundEntityId.trigger[0].entity_id;
                    if (isArray(entityId)) entityId = entityId[0];
                    let name =
                        foundEntityId.history[foundEntityId.history.length - 1]?.assetName +
                        ' - ' +
                        $t(`alarmist.energy.labels.${foundEntityId.history[foundEntityId.history.length - 1].data?.attributes?.device_class || 'unknown'}`);
                    let value = foundEntityId.history[foundEntityId.history.length - 1]?.data?.value ? foundEntityId.history[foundEntityId.history.length - 1]?.data?.value : 'N/A';
                    let lastOccurrence = foundEntityId.history[foundEntityId.history.length - 1].when;
                    let unitOfMeasurement = foundEntityId.history[foundEntityId.history.length - 1]?.data?.attributes?.unit_of_measurement
                        ? foundEntityId.history[foundEntityId.history.length - 1]?.data?.attributes?.unit_of_measurement
                        : '';

                    return {
                        entityId,
                        name,
                        value,
                        lastOccurrence,
                        unitOfMeasurement,
                    };
                } else {
                    let entityId = foundEntityId.trigger[0].entity_id;
                    let name = 'N/A';
                    let value = 'N/A';
                    let lastOccurrence = 'N/A';
                    let unitOfMeasurement = '';
                    return {
                        entityId,
                        name,
                        value,
                        lastOccurrence,
                        unitOfMeasurement,
                    };
                }
            }
        } catch (error) {
            errorMessage.value = error.message;
        }
    };

    const getIconAndClass = (entityId: any) => {
        let iconClass = useIconDict.getIconAndClass(entityId).class;
        let icon = useIconDict.getIconAndClass(entityId).icon;
        return {
            class: iconClass,
            icon: icon,
        };
    };

    function onOptionsUpdated(options: VDataTableOptions) {
        if (firstUpdate.value) {
            firstUpdate.value = false;
            return;
        }
        page.value = options.page;
        pageSize.value = options.itemsPerPage;
        pageCount.value = Math.ceil(total.value / pageSize.value);

        if (options.sortBy.length > 0) {
            //sort by asc or desc
            if (options.sortBy[0].order == 'asc') {
                historyData.value = sortBy(historyData.value, options.sortBy[0].key);
            } else {
                historyData.value = sortBy(historyData.value, options.sortBy[0].key).reverse();
            }
        }

        tableItems.value = historyData.value.slice((page.value - 1) * pageSize.value, page.value * pageSize.value);
    }
</script>
