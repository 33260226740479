import { APIResponseMany, APIResponseSingle, ContentType } from '@/contentTypes';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/departments';
export const API_REF = 'api::department.department';

export async function getDepartments(query = {}): AxiosPromise<APIResponseMany<'Department'>> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}?${searchParams}`);
}
export async function getDepartment(id: number, query = {}): AxiosPromise<APIResponseSingle<'Department'>> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}/${id}?${searchParams}`);
}

export async function createDepartment(data: ContentType<'Department'>): AxiosPromise<APIResponseSingle<'Department'>> {
    return axios.post(`${API_URL}`, { data });
}
export async function updateDepartment(id: number, data: ContentType<'Department'>, query = {}): AxiosPromise<APIResponseSingle<'Department'>> {
    const searchParams = qs.stringify(query);
    return axios.put(`${API_URL}/${id}?${searchParams}`, { data });
}
export async function deleteDepartments(ids: number[]): AxiosPromise<APIResponseMany<'Department'>> {
    const query = qs.stringify({
        filters: {
            id: {
                $in: ids,
            }
        }
    })
    return axios.delete(`${API_URL}/many?${query}`);
}
export async function deleteDepartment(id: number): AxiosPromise<APIResponseSingle<'Department'>> {
    return axios.delete(`${API_URL}/${id}`);
}

export default {
    getDepartments,
    getDepartment,
    createDepartment,
    updateDepartment,
    deleteDepartments,
    deleteDepartment,
};
