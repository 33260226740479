<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('dashboards.dashboard') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <!-- Cards -->
        <v-row class="tw-pt-4">
            <v-col
                v-for="(card, index) in cardsData"
                :key="index"
                class="mx-auto"
                cols="12"
                md="6"
                xxl="3">
                <v-card variant="flat">
                    <template v-slot:prepend>
                        <v-icon :class="getCardData(card).iconClass"> {{ getCardData(card).icon }} </v-icon>
                    </template>
                    <template v-slot:title>
                        <div class="tw-flex tw-justify-end">
                            <p class="tw-text-xl tw-font-semibold">{{ getCardData(card).value }}</p>
                        </div>
                    </template>
                    <v-card-actions class="!tw-p-0">
                        <v-list-item class="w-100 tw-text-sm">
                            <template v-slot:prepend>
                                <p class="tw-text-gray-600">
                                    {{ $t(`dashboards.energy.cards.${card.name}`) }}
                                </p>
                            </template>
                            <template v-slot:append>
                                <div class="justify-self-end !tw-p-0">
                                    <p class="tw-font-semibold">{{ getCardData(card).metric }}</p>
                                </div>
                            </template>
                        </v-list-item>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <!-- Weather Card, Carbon Pie Chart, Energy Flow Card, Tabs -->
        <v-row v-if="done">
            <v-col
                cols="12"
                xl="6"
                xxl="6"
                class="tw-flex tw-w-full tw-flex-col tw-justify-between">
                <!-- Weather Card -->
                <v-card
                    variant="flat"
                    class="mx-auto tw-mb-6 tw-w-full tw-pr-2 tw-pt-2">
                    <v-card-title>
                        <p class="tw-text-base tw-font-semibold">Home</p>
                    </v-card-title>
                    <v-card-subtitle>
                        {{ weather?.description.split('|')[1].trim() }}
                    </v-card-subtitle>
                    <v-card-text class="py-0">
                        <v-row
                            class="tw-flex tw-justify-between"
                            align="center"
                            no-gutters>
                            <v-col
                                class="text-h3"
                                cols="6">
                                {{ Math.floor(weather?.temperature) }}&deg;C
                            </v-col>
                            <v-col
                                cols="3"
                                class="text-right tw-flex">
                                <v-img
                                    contain
                                    height="80"
                                    width="80"
                                    :src="`https://www.ipma.pt/bin/icons/svg/weather/w_ic_${weather?.icon[2]}_${weather?.icon.substring(0, 2)}anim.svg`">
                                </v-img>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <div class="tw-flex tw-justify-between tw-py-3">
                        <v-list-item
                            density="compact"
                            prepend-icon="mdi-weather-windy">
                            <v-list-item-subtitle>{{ weather?.wind_speed }} m/s</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item
                            density="compact"
                            prepend-icon="mdi-weather-pouring">
                            <v-list-item-subtitle>{{ weather?.precipitation_probability * 100 }}%</v-list-item-subtitle>
                        </v-list-item>
                    </div>
                    <div class="tw-flex tw-justify-between tw-py-3">
                        <v-list-item density="compact">
                            <p class="tw-text-sm">Radiação solar</p>
                            <v-list-item-subtitle class="tw-text-center">{{ weather?.ultraviolet }} W/m2</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item density="compact">
                            <p class="tw-text-sm">Humidade</p>
                            <v-list-item-subtitle class="tw-text-center"> {{ weather?.humidity }} %</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item density="compact">
                            <p class="tw-text-sm">Pressão</p>
                            <v-list-item-subtitle class="tw-text-center">{{ weather?.pressure }} hPa</v-list-item-subtitle>
                        </v-list-item>
                    </div>
                </v-card>
                <!-- Carbon Pie Chart -->
                <v-card
                    variant="flat"
                    class="tw-h-[17.1875rem] tw-pr-2 tw-pt-2">
                    <v-card-title>
                        <p class="tw-text-base tw-font-semibold">Energia com baixo teor de carbono consumida</p>
                    </v-card-title>
                    <Chart
                        class="chart"
                        v-if="done"
                        type="pie"
                        :data="
                            getChartData(
                                chartsData?.find((item) => item.type == 'pie'),
                                0,
                            )?.data
                        "
                        :xAxisInfo="
                            getChartData(
                                chartsData?.find((item) => item.type == 'pie'),
                                0,
                            )?.date
                        "
                        :title="
                            getChartData(
                                chartsData?.find((item) => item.type == 'pie'),
                                0,
                            )?.name
                        "
                        :series_name="
                            getChartData(
                                chartsData?.find((item) => item.type == 'pie'),
                                0,
                            )?.unit_of_measurement
                        " />
                </v-card>
            </v-col>
            <!-- Energy Flow Card -->
            <v-col
                cols="12"
                xl="6"
                xxl="6">
                <EnergyFlowCard :energyFlowData="energyFlowData" />
            </v-col>
            <!-- Tabs -->
            <v-col
                cols="12"
                xxl="12"
                v-if="done">
                <v-card
                    variant="flat"
                    class="mx-auto tw-h-full tw-overflow-y-auto">
                    <v-tabs
                        density="dense"
                        color="white"
                        class="tabs-btn tw-mb-1 tw-px-6 tw-pr-2 tw-pt-4"
                        selected-class="tw-bg-primary"
                        hide-slider
                        v-model="tab">
                        <v-tab
                            v-for="(tabData, i) in Object.entries(tabsData)"
                            :key="i"
                            :value="i"
                            >{{ tabData[0] }}</v-tab
                        >
                    </v-tabs>
                    <v-window
                        v-model="tab"
                        class="tw-px-7">
                        <v-window-item
                            v-for="(tabData, i) in Object.entries(tabsData)"
                            :key="i"
                            :value="i">
                            <v-table>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in tabData[1]"
                                        :key="index">
                                        <td class="tw-text-sm tw-font-bold">{{ $t(`dashboards.energy.tabs.${getTabData(tabData[0], index).name}`) }}</td>
                                        <td class="tw-text-sm">{{ getTabData(tabData[0], index).value }} {{ getTabData(tabData[0], index).metric }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-window-item>
                    </v-window>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <!-- Charts -->
            <v-col
                v-for="(chart, index) in chartsData.filter((item) => item.type == 'bar')"
                :key="index"
                cols="12"
                lg="6">
                <v-card
                    variant="flat"
                    v-if="done"
                    class="mx-auto tw-pr-2 tw-pt-2">
                    <template v-slot:prepend>
                        <v-icon class="tw-rounded-md tw-bg-primary-100 tw-bg-opacity-100 tw-p-4 tw-text-primary">mdi-chart-bar</v-icon>
                    </template>
                    <template v-slot:title>
                        <p class="tw-text-base tw-font-semibold">{{ getChartData(chart, index).name }}</p>
                    </template>
                    <Chart
                        class="chart tw-h-96"
                        type="bar"
                        :data="getChartData(chart, index).value"
                        :xAxisInfo="getChartData(chart, index).date"
                        :series_name="getChartData(chart, index).unit_of_measurement"
                        :series_color="getChartData(chart, index).color" />
                </v-card>
            </v-col>
        </v-row>
    </div>
    <v-overlay
        :model-value="isLoading"
        class="tw-items-center tw-justify-center"
        persistent>
        <v-progress-circular
            indeterminate
            size="64"
            color="primary"></v-progress-circular>
    </v-overlay>
</template>

<script setup lang="ts">
    import { ref, provide, computed, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import assets, { getAssets, getAsset, getAssetTotal } from '@/views/AssetsManagement/composables/assets';
    import { getCompany } from '@/views/AssetsManagement/composables/company';
    import { getForecast } from '@/views/Dashboards/composables/weather';
    import { useAuthStore } from '@/store/auth';
    import useIconDictionary from '@/composables/useIconDictionary';
    import { socket } from '@/socket';
    import IoImg from '@/components/ioImg.vue';
    import moment, { RFC_2822 } from 'moment';
    import Chart from '@/components/charts/Chart.vue';
    import EnergyFlowCard from '@/components/EnergyFlowCard.vue';
    import { io } from 'socket.io-client';

    const useAuth = useAuthStore();
    const token = useAuth.token;
    const user = useAuth.user;
    const companyId = useAuth.activeCompany?.id;

    const useIconDict = useIconDictionary();

    const $t = useI18n().t;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('dashboards.title'),
            },
            {
                title: $t('dashboards.energy.title'),
                to: { name: 'EnergyDashboard' },
            },
        ];
    });

    const isLoading = ref(true);
    const errorMessage = ref('');
    const min_date = ref();
    const max_date = ref();
    const tab = ref(1);

    //weather
    const weather = ref();

    //socket
    const SOCKET_URL = import.meta.env.VITE_SOCKET_URL;

    min_date.value = moment().format('YYYY-MM-DD');
    max_date.value = moment().format('YYYY-MM-DD');

    min_date.value = moment(min_date.value).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss');
    max_date.value = moment(max_date.value).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss');

    const done = ref(false);

    const settings = ref();

    //data variables
    const cardsData = ref([]);
    const energyFlowData = ref([]);
    const tabsData = ref([]);
    const chartsData = ref([]);

    //fetch company so we can get settings
    const fetchCompany = async () => {
        try {
            await getCompany(companyId, {
                populate: ['settings'],
            })
                .then((response) => {
                    settings.value = response.data.data.settings;
                })
                .finally(() => {
                    initSocket();
                });
        } catch (error) {
            errorMessage.value = error.message;
        }
    };

    // fetch assets so we can get the data
    const fetchAssets = async () => {
        errorMessage.value = '';
        try {
            let assetsIds = [];
            for (let i = 0; i < settings.value.ioEnergyConfig.dashboard.length; i++) {
                let componentName = Object.keys(settings.value.ioEnergyConfig.dashboard[i])[0];
                if (settings.value.ioEnergyConfig.dashboard[i][componentName].length > 0) {
                    settings.value.ioEnergyConfig.dashboard[i][componentName].forEach((item: any) => {
                        if (componentName == 'tabs') {
                            const tabName = Object.keys(item)[0];
                            item[tabName].forEach((tab: any) => {
                                const key = Object.keys(tab)[0];
                                assetsIds.push(tab.assetId);
                            });
                        } else {
                            const key = Object.keys(item)[0];
                            assetsIds.push(item.assetId);
                        }
                    });
                }
            }
            assetsIds = [...new Set(assetsIds)];
            await getAssets({
                filters: {
                    id: assetsIds,
                },
                stateChanges: true,
                minimalResponse: true,
                significantChangesOnly: true,
                ...(min_date.value && { startTime: moment(min_date.value).format('YYYY-MM-DD[T]HH:mm:ss') }),
                ...(max_date.value && { endTime: moment(max_date.value).format('YYYY-MM-DD[T]HH:mm:ss') }),
            })
                .then((response) => {
                    const data = response.data;
                    for (let i = 0; i < settings.value.ioEnergyConfig.dashboard.length; i++) {
                        const key = Object.keys(settings.value.ioEnergyConfig.dashboard[i])[0];
                        settings.value.ioEnergyConfig.dashboard[i][key].forEach((item: any, index: number) => {
                            if (key == 'tabs') {
                                const tabName = Object.keys(item)[0];
                                tabsData.value[tabName] = [];
                                item[tabName].forEach((tab: any, index: number) => {
                                    const assetId = tab.assetId;
                                    const entityId = tab.entityId;
                                    const foundAsset = data.data.find((item: any) => item.id === assetId);
                                    tabsData.value[tabName].push({ name: tab.name, data: foundAsset?.history?.find((item: any) => item[0].entity_id === entityId) });
                                });
                            } else {
                                const assetId = item.assetId;
                                const entityId = item.entityId;
                                const foundAsset = data.data.find((item: any) => item.id === assetId);
                                if (key == 'cards') cardsData.value.push({ name: item.name, data: foundAsset?.history?.find((item: any) => item[0].entity_id === entityId), entityId: entityId });
                                if (key == 'energyFlow') {
                                    let history = foundAsset?.history?.find((item: any) => item[0].entity_id === entityId);
                                    energyFlowData.value.push({
                                        name: item.name,
                                        state: history ? history[history.length - 1].state : null,
                                        unitOfMeasurement: history ? history[0].attributes.unit_of_measurement : '',
                                        entityId: entityId,
                                    });
                                }
                                if (key == 'charts') chartsData.value.push({ data: foundAsset?.history?.find((item: any) => item[0].entity_id === entityId), type: item.type });
                            }
                        });
                    }
                })
                .finally(() => {
                    //set tabsData.value values hardcoded (just for the demo)
                    for (let consumption of tabsData.value.Consumo) {
                        if (consumption.name == 'date') consumption.data[0].state = moment().format('DD/MM/YYYY');
                        if (consumption.name == 'knownLevel') consumption.data[consumption.data.length - 1].state = '50';
                        if (consumption.name == 'alwaysOn') consumption.data[consumption.data.length - 1].state = '1846.8';
                        if (consumption.name == 'consumerElectronics') consumption.data[consumption.data.length - 1].state = '3078';
                        if (consumption.name == 'hvac') consumption.data[consumption.data.length - 1].state = '6156';
                        if (consumption.name == 'lighting') consumption.data[consumption.data.length - 1].state = '0';
                        if (consumption.name == 'majorAppliances') consumption.data[consumption.data.length - 1].state = '769.5';
                        if (consumption.name == 'other') consumption.data[consumption.data.length - 1].state = '307.8';
                        if (consumption.name == 'smallAppliances') consumption.data[consumption.data.length - 1].state = '1539';
                        if (consumption.name == 'unknown') consumption.data[consumption.data.length - 1].state = '1692.9';
                    }
                    for (let billing of tabsData.value.Faturação) {
                        if (billing.name == 'billingAlwaysOn') billing.data[billing.data.length - 1].state = '0.46';
                        if (billing.name == 'billingConsumerElectronics') billing.data[billing.data.length - 1].state = '0.39';
                        if (billing.name == 'billingHvac') billing.data[billing.data.length - 1].state = '1.56';
                        if (billing.name == 'billingLighting') billing.data[billing.data.length - 1].state = '0';
                        if (billing.name == 'billingMajorAppliances') billing.data[billing.data.length - 1].state = '0.78';
                        if (billing.name == 'billingOther') billing.data[billing.data.length - 1].state = '0.07';
                        if (billing.name == 'billingSmallAppliances') billing.data[billing.data.length - 1].state = '0.07';
                        if (billing.name == 'billingUnknown') billing.data[billing.data.length - 1].state = '0.43';
                    }
                });
        } catch (error) {
            errorMessage.value = error.message;
        }
    };

    //aggregate data by hours (for charts)
    const aggregateByHours = (array: any) => {
        let lastValue;
        let firstValue;

        // Calculate the hourly k
        const hourlyIncrease = array.reduce((result, entry, index, array) => {
            if (index == 0) {
                firstValue = parseFloat(entry.state);
            } else {
                const currentHour = moment(entry.last_changed).hour();
                const previousHour = moment(array[index - 1].last_changed).hour();

                if (currentHour !== previousHour) {
                    //if current hour equals next midnight hour (00:00) use the value 23:59:59
                    if (currentHour === 0) {
                        //if previous hour is 00:00, use the value from the last entry of the previous day
                        lastValue = parseFloat(array[index - 1].state);
                        let increase = lastValue - firstValue;
                        if (increase < 0) increase = lastValue;
                        result.push({ hour: previousHour, increase });
                        firstValue = parseFloat(entry.state);
                    } else {
                        lastValue = parseFloat(entry.state);
                        //const increase = parseFloat(entry.state) - parseFloat(array[index - 1].state);
                        let increase = lastValue - firstValue;
                        if (increase < 0) increase = lastValue;
                        result.push({ hour: previousHour, increase });
                        firstValue = parseFloat(entry.state);
                    }
                }
            }
            return result;
        }, []);

        hourlyIncrease.sort((a, b) => a.hour - b.hour);
        hourlyIncrease.forEach((item: any) => {
            item.hour = moment().hour(item.hour).format('HH:00');
        });

        return hourlyIncrease;
    };

    //init socket
    const initSocket = async () => {
        const socket = io(SOCKET_URL, {
            reconnectionDelayMax: 10000,
            query: {
                token: token,
                companyId: companyId,
            },
        });
        socket.on('connect', () => {
            let energyFlowSettings = settings?.value?.ioEnergyConfig?.dashboard.find((item: any) => Object.keys(item)[0] == 'energyFlow');
            socket.on('socket', (data) => {
                for (let i = 0; i < energyFlowSettings.energyFlow.length; i++) {
                    if (data.id == i + 2) {
                        energyFlowData.value[i].state = data?.event?.variables?.trigger?.to_state?.state;
                        energyFlowData.value[i].unitOfMeasurement = data?.event?.variables?.trigger?.to_state?.attributes?.unit_of_measurement;
                    }
                }
            });
            socket.on('ready', (data) => {
                if (data == true) {
                    let idCount = 1;
                    for (let i = 0; i < energyFlowSettings.energyFlow.length; i++) {
                        socket.emit('socket', {
                            id: idCount + 1,
                            type: 'subscribe_trigger',
                            trigger: {
                                platform: 'state',
                                entity_id: energyFlowSettings.energyFlow[i].entityId,
                            },
                        });
                        idCount++;
                    }
                }
            });
        });
    };

    //forecast
    const forecast = async () => {
        const currentTime = moment();
        const arrivalTime = currentTime.add(1, 'minutes');
        try {
            await getForecast({
                arrivalTime: arrivalTime.format('YYYY-MM-DD[T]HH:mm:ss'),
            })
                .then((response) => {
                    weather.value = response?.data[0]?.forecast[0];
                })
                .finally(() => {
                    done.value = true;
                    isLoading.value = false;
                });
        } catch (error) {
            done.value = true;
            isLoading.value = false;
            console.log(error);
        }
    };

    fetchCompany().then(() => {
        fetchAssets().finally(() => {
            forecast();
        });
    });

    const getCardData = (card: any) => {
        let icon = getIconAndClass(card.entityId).icon;
        let iconClass = getIconAndClass(card.entityId).class;
        //check if entity id exists in energyFlowData and its being updated by socket
        //if its being updated by socket, use the updated value instead of the one from the history
        let foundEntityId = energyFlowData.value.find((item: any) => item.entityId == card.entityId);
        if (foundEntityId) {
            let value = foundEntityId.state == 'unavailable' ? 'N/A' : foundEntityId.state;
            let metric = foundEntityId.unitOfMeasurement == 'unavailable' ? '' : foundEntityId.unitOfMeasurement;
            return { value, metric, icon, iconClass };
        } else {
            let value = card.data ? card.data[card.data.length - 1].state : 'N/A';
            let metric = card.data ? card.data[0].attributes.unit_of_measurement : '';
            return { value, metric, icon, iconClass };
        }
    };

    const getTabData = (tab: string, index: number) => {
        const tabItem = tabsData.value[tab][index];
        const hasData = tabItem.data;

        const name = hasData ? tabItem.name : 'N/A';
        const value = hasData ? (tabItem.name === 'date' ? tabItem.data[0].state : tabItem.data[tabItem.data.length - 1].state) : 'N/A';
        const metric = hasData ? tabItem.data[0].attributes.unit_of_measurement : '';

        return { name, value, metric };
    };

    const getChartData = (chart: any, index: number) => {
        if (!chart) return;
        let hourlyIncrease = aggregateByHours(chart.data);
        let value = hourlyIncrease.map((item: any) => parseFloat(item?.increase).toFixed(3));
        let date = hourlyIncrease.map((item: any) => item.hour);
        let name = chart.data[0].attributes.friendly_name;
        let unit_of_measurement = chart.data[0].attributes.unit_of_measurement;
        let color;
        if (chart.type == 'bar') {
            let filterByTypeBar = chartsData.value.filter((item: any) => item.type == 'bar');
            if (filterByTypeBar.length > 1) {
                if (index === 0) color = '#3F51B5';
                if (index === 1) color = '#4CAF50';
                if (index === 2) color = '#FF5722';
            }
        }
        return { value, date, name, unit_of_measurement, color };
    };

    const getIconAndClass = (entityId: any) => {
        let iconClass = useIconDict.getIconAndClass(entityId).class;
        let icon = useIconDict.getIconAndClass(entityId).icon;
        return {
            class: iconClass,
            icon: icon,
        };
    };
</script>

<style scoped></style>
