<template>
    <div
        class="canvas"
        ref="canvas"
        :id="canvasId"></div>
</template>

<script setup lang="ts">
    import { onMounted } from 'vue';
    import { ref } from 'vue';
    import Konva from 'konva';
    import { useMedia } from '@/composables/useMedia';
    import { computed } from 'vue';
    import theme from '../../theme';
    import { KonvaEventObject } from 'konva/lib/Node';
    import { ContentType, Media } from '@/contentTypes';
    import { watch } from 'vue';
    // import { updateDeviceInstalled } from '@/api/devicesInstalled';
    import { watchEffect } from 'vue';
    import { useBlueprintStore } from '@/store/blueprint';
    import { useAuthStore } from '@/store/auth';

    const useAuth = useAuthStore();
    const token = useAuth.token;
    const user = useAuth.user;

    Konva.hitOnDragEnabled = true;

    // const props = defineProps<{
    //     showSidebar?: boolean;
    //     floor: Floor;
    //     devicesInstalled?: DeviceInstalled[];
    //     readOnly?: boolean;
    // }>();

    //declare all props variable just for testing purposes
    const props = {
        showSidebar: false,
        floor: {
            id: 1,
            blueprint: {
                id: 214,
                width: 1920,
                height: 1080,
            },
        },
        devicesInstalled: [
            {
                id: 1,
                device: {
                    id: 1,
                    picture: 1,
                },
                blueprintData: {
                    x: 0,
                    y: 0,
                    scaleX: 1,
                    scaleY: 1,
                },
            },
        ],
        readOnly: false,
    };

    const canvasId = `canvas_${props.floor.id}`;
    const canvas = ref<HTMLDivElement>();
    const layer = new Konva.Layer();
    let stage: Konva.Stage;
    let tr: Konva.Transformer;

    const isMounted = ref(false);
    const selectedDevice = ref(0);
    const blueprintStore = useBlueprintStore();
    const currentDevices = ref<number[]>([]);

    const CIRCLE_RADIUS = 24;
    const DELETE_ICON_SIZE = 40;

    const deleteSvg = new Image();
    deleteSvg.src = '/icons/delete.svg';

    const blueprintBiggestSide = computed(() => {
        if (props.floor.blueprint && props.floor.blueprint.width && props.floor.blueprint.height) {
            return props.floor.blueprint.width > props.floor.blueprint.height ? 'width' : 'height';
        }
        return 'width';
    });

    const blueprintSmallestSide = computed(() => {
        if (props.floor.blueprint && props.floor.blueprint.width && props.floor.blueprint.height) {
            return props.floor.blueprint.width < props.floor.blueprint.height ? 'width' : 'height';
        }
        return 'width';
    });

    const blueprintBiggestSideValue = computed(() => {
        if (props.floor.blueprint && props.floor.blueprint.width && props.floor.blueprint.height) {
            return props.floor.blueprint.width > props.floor.blueprint.height ? props.floor.blueprint.width : props.floor.blueprint.height;
        }
        return 0;
    });

    const blueprintSmallestSideValue = computed(() => {
        if (props.floor.blueprint && props.floor.blueprint.width && props.floor.blueprint.height) {
            return props.floor.blueprint.width < props.floor.blueprint.height ? props.floor.blueprint.width : props.floor.blueprint.height;
        }
        return 0;
    });

    const blueprintAspectRatio = computed(() => {
        if (props.floor.blueprint && blueprintBiggestSideValue.value && blueprintSmallestSideValue.value) {
            return blueprintSmallestSideValue.value / blueprintBiggestSideValue.value;
        }
        return 1;
    });

    const blueprintStageWidth = computed(() => {
        return (props.floor?.blueprint?.width as number) > (props.floor?.blueprint?.height as number) ? 1920 : 1920 * blueprintAspectRatio.value;
    });

    const blueprintStageHeight = computed(() => {
        return (props.floor?.blueprint?.width as number) > (props.floor?.blueprint?.height as number) ? 1920 * blueprintAspectRatio.value : 1920;
    });

    function updateDeviceData(shape: Konva.Shape) {
        const x = shape.x();
        const y = shape.y();
        const scaleX = shape.scaleX();
        const scaleY = shape.scaleY();
        const deviceId = shape.attrs.deviceID;
        blueprintStore.updateDevice(deviceId, {
            x,
            y,
            scaleX,
            scaleY,
        });
        // updateDeviceInstalled(deviceId, {
        //     blueprintData: {
        //         x,
        //         y,
        //         scaleX,
        //         scaleY,
        //     },
        // });
    }

    function onDeviceClick(e: KonvaEventObject<any>) {
        const shape = e.target;
        if (selectedDevice.value === shape.attrs.deviceID) {
            return;
        } else if (selectedDevice.value !== 0) {
            const oldShape = layer.findOne((node: Konva.Node) => {
                return node.attrs.deviceID === selectedDevice.value;
            });

            if (oldShape) {
                // remove circle and delete icon
                const circle = layer.findOne((node: Konva.Node) => {
                    return node.attrs.name === 'circleRemove';
                });
                if (circle) {
                    circle.remove();
                }
                const deleteIcon = layer.findOne((node: Konva.Node) => {
                    return node.attrs.name === 'iconRemove';
                });
                if (deleteIcon) {
                    deleteIcon.remove();
                }
            }
        }

        selectedDevice.value = shape.attrs.deviceID;

        if (tr) tr.destroy();

        tr = new Konva.Transformer({
            nodes: [shape],
            keepRatio: true,
            enabledAnchors: ['top-right', 'bottom-left', 'bottom-right'],
            anchorSize: 10,
            rotateEnabled: false,
        });

        layer.add(tr);

        const circle = new Konva.Circle({
            x: shape.x(),
            y: shape.y(),
            width: CIRCLE_RADIUS * 2 * Math.abs(shape.scaleX()),
            height: CIRCLE_RADIUS * 2 * Math.abs(shape.scaleY()),
            fill: theme.colors.gray['200'],
            draggable: false,
            name: 'circleRemove',
        });
        const deleteIcon = new Konva.Image({
            x: shape.x() - (DELETE_ICON_SIZE / 2) * Math.abs(shape.scaleX()),
            y: shape.y() - (DELETE_ICON_SIZE / 2) * Math.abs(shape.scaleY()),
            image: deleteSvg,
            width: DELETE_ICON_SIZE * Math.abs(shape.scaleX()),
            height: DELETE_ICON_SIZE * Math.abs(shape.scaleY()),
            draggable: false,
            name: 'iconRemove',
        });

        function removeDevice() {
            shape.remove();
            circle.remove();
            deleteIcon.remove();
            tr.destroy();
            layer.draw();
            blueprintStore.updateDevice(shape.attrs.deviceID, null);
            // updateDeviceInstalled(shape.attrs.deviceID, {
            //     blueprintData: null,
            // });
        }

        circle.on('click tap', removeDevice);
        deleteIcon.on('click tap', removeDevice);

        shape.on('dragmove', () => {
            circle.x(shape.x());
            circle.y(shape.y());
            deleteIcon.x(shape.x() - (DELETE_ICON_SIZE / 2) * shape.scaleX());
            deleteIcon.y(shape.y() - (DELETE_ICON_SIZE / 2) * shape.scaleY());
            layer.draw();
        });
        shape.on('transform', () => {
            // resize the circle and delete icon with the shape together
            circle.width(CIRCLE_RADIUS * 2 * Math.abs(shape.scaleX()));
            circle.height(CIRCLE_RADIUS * 2 * Math.abs(shape.scaleY()));
            circle.position({
                x: shape.x(),
                y: shape.y(),
            });

            deleteIcon.width(DELETE_ICON_SIZE * Math.abs(shape.scaleX()));
            deleteIcon.height(DELETE_ICON_SIZE * Math.abs(shape.scaleY()));
            deleteIcon.position({
                x: shape.x() - (DELETE_ICON_SIZE / 2) * Math.abs(shape.scaleX()),
                y: shape.y() - (DELETE_ICON_SIZE / 2) * Math.abs(shape.scaleY()),
            });
            layer.draw();
        });
        layer.add(circle);
        layer.add(deleteIcon);
    }

    function reDraw() {
        if (!canvas.value || blueprintBiggestSide.value == 'height') {
            return;
        }
        stage.width(canvas.value?.offsetWidth);
        stage.height(canvas.value?.offsetWidth * blueprintAspectRatio.value);
        const scale = stage.width() / blueprintStageWidth.value;
        layer.scaleX(scale);
        layer.scaleY(scale);

        stage.draw();
    }

    watch(
        () => props.showSidebar,
        async () => {
            await new Promise((resolve) => setTimeout(resolve, 250));
            reDraw();
        },
    );

    watchEffect(
        () => {
            let devices = props.devicesInstalled ? props.devicesInstalled : blueprintStore.getDevicesForBlueprint;
            if (!isMounted.value || !devices.length) return;
            devices.forEach((device) => {
                if (currentDevices.value.includes(device.id as number)) return;
                currentDevices.value.push(device.id as number);
                Konva.Image.fromURL(
                    useMedia(device.device?.picture as Media, 'Department').original,
                    function (image) {
                        image.setAttrs({
                            x: device.blueprintData?.x,
                            y: device.blueprintData?.y,
                            width: 150,
                            height: 150,
                            fill: 'white',
                            draggable: !props.readOnly,
                            stroke: theme.colors.primary.DEFAULT,
                            strokeWidth: 5,
                            cornerRadius: 6,
                            zIndex: 1,
                            deviceID: device.id,
                            scaleX: device.blueprintData?.scaleX,
                            scaleY: device.blueprintData?.scaleY,
                        });

                        if (!props.readOnly) {
                            image.on('transformend', (e: KonvaEventObject<any>) => {
                                const shape = e.target;
                                updateDeviceData(shape as Konva.Shape);
                            });
                            image.on('dragend', (e: KonvaEventObject<any>) => {
                                const shape = e.target;
                                updateDeviceData(shape as Konva.Shape);
                            });
                            image.on('click tap', onDeviceClick);
                        }
                        layer.add(image);
                    },
                    (error) => {
                        console.log('error', error);
                    },
                );
            });
        },
        {
            flush: 'post',
        },
    );

    onMounted(() => {
        if (!canvas.value) {
            return;
        }

        stage = new Konva.Stage({
            container: canvasId,
            width: canvas.value?.offsetWidth,
            height: canvas.value.offsetHeight || canvas.value?.offsetWidth * blueprintAspectRatio.value,
            draggable: true,
        });
        stage.add(layer);

        //declare a variable just for testing purposes
        let examplePicture = {
            url: "/uploads/Sample_Hauseit_Custom_2_D_Floorplan_1_d71bd0cf9d.jpg"
        }

        //for production, use useMedia(props.floor.blueprint).original
        //instead of useMedia(examplePicture as Media, 'Department').original

        Konva.Image.fromURL(useMedia(examplePicture as Media, 'Department').original, function (image) {
            const scale = blueprintBiggestSide.value == 'width' ? stage.width() / blueprintStageWidth.value : stage.height() / blueprintStageHeight.value;
            image.setAttrs({
                x: 0,
                y: 0,
                zIndex: -1,
                width: blueprintStageWidth.value,
                height: blueprintStageHeight.value,
                draggable: false,
            });

            layer.add(image);
            // scale the layer to fit the stage, calculate the scale using the stage width and the layer width
            layer.scaleX(scale);
            layer.scaleY(scale);

            isMounted.value = true;
        });

        const container = stage.container();
        container.addEventListener('dragover', (e) => {
            e.preventDefault();
        });
        if (!props.readOnly)
            container.addEventListener('drop', (e) => {
                e.preventDefault();

                const device: ContentType<'Department'> = JSON.parse(e.dataTransfer?.getData('device') || '');
                if (!device) return;
                currentDevices.value.push(device.id as number);
                blueprintStore.updateDevice(device.id as number, {
                    x: 0,
                    y: 0,
                    scaleX: 1,
                    scaleY: 1,
                });
                stage.setPointersPositions(e);
                Konva.Image.fromURL(useMedia(device.device?.picture as Media, 'Department').original, function (image) {
                    image.setAttrs({
                        x: 0,
                        y: 0,
                        width: 150,
                        height: 150,
                        fill: 'white',
                        draggable: true,
                        stroke: theme.colors.primary.DEFAULT,
                        strokeWidth: 5,
                        cornerRadius: 6,
                        deviceID: device.id,
                    });
                    image.on('transformend', (e: KonvaEventObject<any>) => {
                        const shape = e.target;
                        updateDeviceData(shape as Konva.Shape);
                    });
                    image.on('dragend', (e: KonvaEventObject<any>) => {
                        const shape = e.target;
                        updateDeviceData(shape as Konva.Shape);
                    });
                    image.on('click tap', onDeviceClick);

                    // on image move

                    layer.add(image);

                    const pointerPosition = stage.getPointerPosition();
                    if (pointerPosition) {
                        image.position(pointerPosition);
                    }
                    image.draggable(true);
                });
            });

        addEventListener('resize', reDraw);

        //#region PAN
        function getDistance(p1: { x: number; y: number }, p2: { x: number; y: number }) {
            return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
        }

        function getCenter(p1: { x: number; y: number }, p2: { x: number; y: number }) {
            return {
                x: (p1.x + p2.x) / 2,
                y: (p1.y + p2.y) / 2,
            };
        }

        let lastCenter: any = null;
        let lastDist = 0;
        let dragStopped = false;

        stage.on('touchmove', (e) => {
            e.evt.preventDefault();
            let touch1 = e.evt.touches[0];
            let touch2 = e.evt.touches[1];

            // we need to restore dragging, if it was cancelled by multi-touch
            if (touch1 && !touch2 && !stage.isDragging() && dragStopped) {
                stage.startDrag();
                dragStopped = false;
            }

            if (touch1 && touch2) {
                // if the stage was under Konva's drag&drop
                // we need to stop it, and implement our own pan logic with two pointers
                if (stage.isDragging()) {
                    dragStopped = true;
                    stage.stopDrag();
                }

                let p1 = {
                    x: touch1.clientX,
                    y: touch1.clientY,
                };
                let p2 = {
                    x: touch2.clientX,
                    y: touch2.clientY,
                };

                if (!lastCenter) {
                    lastCenter = getCenter(p1, p2);
                    return;
                }
                let newCenter = getCenter(p1, p2);

                let dist = getDistance(p1, p2);

                if (!lastDist) {
                    lastDist = dist;
                }

                // local coordinates of center point
                let pointTo = {
                    x: (newCenter.x - stage.x()) / stage.scaleX(),
                    y: (newCenter.y - stage.y()) / stage.scaleX(),
                };

                let scale = stage.scaleX() * (dist / lastDist);

                stage.scaleX(scale);
                stage.scaleY(scale);

                // calculate new position of the stage
                let dx = newCenter.x - lastCenter.x;
                let dy = newCenter.y - lastCenter.y;

                let newPos = {
                    x: newCenter.x - pointTo.x * scale + dx,
                    y: newCenter.y - pointTo.y * scale + dy,
                };

                stage.position(newPos);

                lastDist = dist;
                lastCenter = newCenter;
            }
        });

        stage.on('touchend', function (e) {
            lastDist = 0;
            lastCenter = null;
        });

        stage.on('wheel', (e) => {
            e.evt.preventDefault();
            if (!stage || !stage.getPointerPosition()) return;
            const scaleBy = 1.1;
            const oldScale = stage.scaleX();

            const pointerX = stage.getPointerPosition()?.x ?? 0;
            const pointerY = stage.getPointerPosition()?.y ?? 0;

            const mousePointTo = {
                x: pointerX / oldScale - stage.x() / oldScale,
                y: pointerY / oldScale - stage.y() / oldScale,
            };

            const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

            stage.scale({ x: newScale, y: newScale });

            const newPos = {
                x: -(mousePointTo.x - pointerX / newScale) * newScale,
                y: -(mousePointTo.y - pointerY / newScale) * newScale,
            };

            stage.position(newPos);
        });
        //#endregion
    });
</script>

<style scoped>
    .canvas {
        /* height: calc(200svh - 20rem); */
    }
</style>
